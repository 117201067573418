import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message, DatePicker, Radio, Table, Drawer, Button } from 'antd';
import TradeApi from '../../../common/api/trade';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { CSVLink } from 'react-csv';
import IconFont from '../../../components/iconfont';
import { API_HOST, formatMoney } from '../../../common/utils';

const { RangePicker } = DatePicker;

const liabilitiesTotalMap = new Map()

const BalanceSheet = () => {
    
    const queryClient = useQueryClient();
    const [messageApi, contextHolder] = message.useMessage();
    const [list, setList] = useState<any[]>([]);
    const [expensesList, setExpensesList] = useState<any[]>([]);
    const [date, setDate] = useState<{ start: string; end: string; }>();
    const [inc, setInc] = useState('');
    
    const [testData, setTestData] = useState<any>([]);
    
    const [csv, setCsv] = useState<any>([]);
    const [type, setType] = useState(0);
    const [details, setDetails] = useState<any>({});
    const [open, setOpen] = useState<boolean>(false);
    const [value, setValue] = useState<number>(0);
    const [bankAmount, setBankAmount] = useState<number>(0);
    const [creditAmount, setCreditAmount] = useState<number>(0);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [month, setMonth] = useState([]);
    const [size, setSize] = useState('month');
    const [monthType, setMonthType] = useState(12);
    
    console.log('testData', testData[ 0 ])
    useEffect(() => {
        // renderCSV();
    }, [list, inc])
    
    useEffect(() => {
        // getSummary.mutate({start: '2020-01-1', end: '2021-01-1'});
    }, [])
    
    const columns = [
        {
            key: 'day',
            dataIndex: 'day',
            title: 'Date',
            width: 150
        },
        {
            key: 'dec',
            dataIndex: 'desc',
            width: 300,
            title: 'Description'
        },
        {
            key: 'amount',
            dataIndex: 'amount',
            title: 'Amount',
            width: 100
        },
        {
            key: 'is_bank',
            dataIndex: 'is_bank',
            title: 'Is Bank',
            render: (_data: boolean) => {
                return <div>{ _data ? 'bank' : 'card' }</div>
            },
            width: 100
        },
        {
            key: 'trade',
            dataIndex: 'trade_type_info',
            title: 'Trade Type',
            width: 150
        },
        {
            key: 'type',
            dataIndex: 'type_info',
            title: 'Type',
            width: 100
        },
        {
            key: 'kind',
            dataIndex: 'kind_number',
            title: 'Kind Number',
            width: 100
        },
        {
            key: 'amount_front',
            dataIndex: 'amount_front',
            title: '消费前',
            width: 100,
            render: (r: any) => {
                return <div>{ formatMoney(r) }</div>
            }
        },
        {
            key: 'amount_cal',
            dataIndex: 'amount_cal',
            title: 'Amount Cal',
            width: 120,
            render: (r: any) => {
                return <div>{ formatMoney(r) }</div>
            }
        },
        {
            key: 'amount_after',
            dataIndex: 'amount_after',
            title: '消费后',
            width: 100,
            render: (r: any) => {
                return <div>{ formatMoney(r) }</div>
            }
        }
    ];
    
    
    const getSummary = useMutation(TradeApi.getSummary, {
        onSuccess: (result, params) => {
            if (params.export) {
                if (result.status === 200 && result.data) {
                    const blob = new Blob([result.data]);
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = `${ Date.now() }.xlsx`;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    message.success('Operation Success')
                    return
                }
            }
            
            if (result.data.status === 'success') {
                
                liabilitiesTotalMap.clear()
                setBankAmount(result.data.bank_amount);
                setCreditAmount(result.data.credit_amount);
                
                const _list = result.data.data;
                
                formatCategory(_list);
                
                
                setInc('');
                
                
                setType(2);
            }
        }
    })
    
    //返回格式：{title:10000-9000,data:{title:'月',date：{}}}
    const formatCategory = (data: any) => {
        let title: any[] = [];
        //1.根标题
        //2.数据
        
        let _month: any = [];
        
        
        data.forEach((item: any, monthIndex: number) => {
            item.data.forEach((_item: any, index: number) => {
                if (title.length <= 9) {
                    title.push({
                        title: _item.name,
                        category: []
                    });
                }
            });
            
            _month.push(item.title);
        })
        
        console.log(_month);
        
        setMonth(_month);
        
        formatChildrenCategory(data, title)
    }
    
    const formatChildrenCategory = (data: any, title: any) => {
        
        const _title = [...title];
        
        const _list: any[] = [];
        
        
        //遍历月份
        data.forEach((item: any) => {
            // 遍历类型
            item.data.forEach((_item: any, monthIndex: number) => {
                
                title.forEach((_ttitle: any, index: number) => {
                    //大类相同
                    if (_ttitle.title === _item.name) {
                        
                        
                        _item.category.forEach((_category: any) => {
                            
                            if (_ttitle.title.charAt(0) === _category.name.charAt(0)) {
                                
                                let status = true;
                                
                                _title[ _category.name.charAt(0) ].category.forEach((categoryTitle: any) => {
                                    if (categoryTitle.name === _category.name) {
                                        status = false;
                                    }
                                })
                                
                                
                                if (status) {
                                    _title[ _category.name.charAt(0) ].category.push({
                                        ..._category,
                                        value: new Array(12).fill(null)
                                    });
                                }
                                
                                
                            }
                        })
                    }
                })
                
            })
        })
        
        // _title.forEach((item, index) => {
        //     if (index > 0) {
        //         _list.push(item);
        //     }
        // })
        
        // setTestData(_list);
        
        formateMonth(data, _title);
        
    }
    
    
    const formateMonth = (data: any, title: any) => {
        
        const _title = [...title];
        //遍历月份
        data.forEach((item: any, meIndex: number) => {
            // 遍历类型
            item.data.forEach((_item: any, monthIndex: number) => {
                
                _item.category.forEach((_category: any) => {
                    
                    
                    _title[ parseInt(_item.name.charAt(0)) ].category.forEach((test: any, valueIndex: number) => {
                        if (test.name === _category.name) {
                            _title[ parseInt(_item.name.charAt(0)) ].category[ valueIndex ].value[ meIndex ] = {
                                ..._category,
                                to: item.bank_amount,
                                form: item.credit_amount
                            };
                        }
                    })
                })
            });
        });
        
        
        let _arr: any[] = [];
        _title.forEach((item, index) => {
            if (index <= 3 && index > 0) {
                _arr.push(item);
            }
        })
        
        _arr.map((item) => {
            
            return {
                ...item,
                category: item.category.sort((i: any, l: any) => {
                    return i.name.substring(0, 5) - l.name.substring(0, 5);
                })
            }
            
        })
        
        
        setTestData(_arr);
        setType(2);
        
        
        const spliceList = [..._arr].splice(1, _arr.length);
        
        console.log(_arr);
        
        setExpensesList(spliceList);
    }
    
    const changeDate = (v: any, a: any) => {
        setType(0);
        setValue(0);
        if (v) {
            setDate({ start: a[ 0 ], end: a[ 0 ] });
            getSummary.mutate({ start: a[ 0 ], end: a[ 1 ] });
        }
    }
    
    const changeYear = (e: any) => {
        
        setType(0);
        setValue(e.target.value)
        
        let startDate: any = dayjs().year() + '-01-01';
        let endDate: any = dayjs().year() + '-12-' + dayjs(`${ dayjs().year() }-12-01`).daysInMonth();
        
        
        if (e.target.value === 2) {
            // @ts-ignore
            startDate = (dayjs().year() - 1) + '-01-01';
            // @ts-ignore
            endDate = (dayjs().year() - 1) + '-12-' + dayjs(`${ dayjs().year() }-12-01`).daysInMonth();
        }
        
        
        setDate({ start: startDate, end: endDate });
        getSummary.mutate({ start: startDate, end: endDate });
    }
    
    const getData = () => {
        let status = false;
        const _list = [...list];
        const _arr: any = [];
        
        
    }
    
    const renderCSV = () => {
        
        
        let positive = 0;
        let negative = 0;
        
        const _list = [];
        const _title = ['', inc, ''];
        const _type = ['', 'Profit and Loss', ''];
        const _date = ['', `${ date?.start } - ${ date?.end }`, ''];
        
        
        _list.push(_title);
        _list.push(_type);
        _list.push(_date);
        
        list.forEach((item) => {
            if (item.category.length > 0) {
                _list.push([item.name, '', '']);
                item.category.map((_item: any) => {
                    _list.push([`  ${ _item.name }`, '', _item.amount.toFixed(2)]);
                    
                    const amount = _item.amount.toFixed(2);
                    if (amount > 0) {
                        positive = parseFloat(amount) + positive;
                    } else {
                        negative -= amount;
                    }
                })
            }
        })
        
        const sum: number = positive - negative;
        
        // if (positive && negative) {
        //   _list.push(['Total Income', '', `$${sum}`])
        // }
        // setCsv(_list);
        
        // @ts-ignore
        // setTotal(sum.toFixed(2));
        
    }
    
    
    const sumOne = () => {
        let sum = 0;
        if (testData[ 0 ].category.length > 0) {
            testData[ 0 ].category.forEach((i: any) => {
                if (i.name.substring(3, 5) === '00') {
                    sum = sum + parseFloat(i.value[ i.value.length - 1 ].amount.toFixed(2));
                }
                
            })
            
        } else {
            sum = 0;
        }
        
        return formatMoney(sum);
    }
    
    const sumOneMonthFour = (data: any, index: number) => {
        let sum = 0;
        if (data.category.length > 0) {
            data.category.forEach((i: any) => {
                if (data.title.split(' ')[ 0 ].substring(2, 5) === '000' && i.name.substring(3, 5) === '00') {
                    sum = sum + parseFloat(i.value[ index ]?.amount || 0);
                }
            })
        } else {
            sum = 0;
        }
        
        return formatMoney(sum);
    }
    
    const sum = (data: any, index: number) => {
        let _sum = 0;
        
        if (data.category.length > 0) {
            data.category.forEach((i: any) => {
                if (i.name.substring(3, 5) === '00') {
                    _sum = _sum + parseFloat(i.value[ i.value.length - 1 ].amount.toFixed(2));
                }
            })
            
        } else {
            _sum = 0;
        }
        
        liabilitiesTotalMap.set(`total-${ index }`, _sum)
        return formatMoney(_sum);
    }
    
    const sumMonth = (data: any, index: number, parentIndex: number) => {
        let _sum = 0;
        
        if (data.category.length > 0) {
            data.category.forEach((i: any) => {
                if (data.title.split(' ')[ 0 ].substring(2, 5) === '000' && i.name.substring(3, 5) === '00') {
                    _sum = _sum + parseFloat((i.value[ index ]?.amount || 0).toFixed(2));
                }
            })
            
        } else {
            _sum = 0;
        }
        
        liabilitiesTotalMap.set(`m-${ parentIndex }-${ index }`, _sum)
        return formatMoney(_sum);
    }
    
    const totalExpenses = () => {
        return formatMoney(liabilitiesTotalMap.get('total-0') + liabilitiesTotalMap.get('total-1'));
    }
    
    const totalExpensesMonth = (index: number) => {
        let v = 0
        liabilitiesTotalMap.forEach((value, key) => {
            let prefix = key.split('-')[ 2 ]; // 获取 key 的前缀
            if (Number(prefix) === index) {
                v += value
            }
        });
        
        return formatMoney(v);
    }
    
    const operatingIncome = () => {
        const four = sumOne();
        const total = totalExpenses();
        // @ts-ignore
        const _sum = four - total;
        
        
        return formatMoney(_sum);
    }
    
    const onClose = () => {
        setOpen(false);
        setDetails([]);
    }
    
    const openDrawer = (data: any, vIndex: number) => {
        setOpen(true);
        const _data = { ...data };
        let initNumber = 0;
        
        
        if (_data.name.indexOf('11000') >= 0) {
            initNumber = data.to ?? 0;
        } else if (_data.name.indexOf('21000') >= 0) {
            initNumber = data.form ?? 0;
        } else {
            initNumber = 0;
        }
        
        
        console.log(_data.details, initNumber);
        
        _data.details = formatDetails(_data.details, initNumber);
        
        _data[ 'monthIndex' ] = vIndex
        setDetails(_data);
    }
    
    
    const formatDetails = (_list: any[], num: number) => {
        let init: any = num;
        const arr = _list.map((item) => {
            
            const front = init;
            const after = (init + item.amount_cal).toFixed(2);
            
            init = parseFloat(after);
            
            return {
                ...item,
                amount_front: front,
                amount_after: parseFloat(after)
            }
        })
        
        setTotalAmount(init);
        return arr;
    }
    const onHandleExport = () => {
        if (!date?.start) {
            messageApi.error('Please select the date range');
            return
        }
        
        // window.open(`${API_HOST}v2/trade_summary/?start=${ encodeURIComponent(date?.start) }&end=${ encodeURIComponent(date?.end) }&export=balanceSheet`)
        
        getSummary.mutate({ start: date?.start, end: date?.end, export: 'balanceSheet',type: size });
    }
    
    const onHandleExportSub = ()=>{
        if (!date?.start) {
            messageApi.error('Please select the date range');
            return
        }
        getSummary.mutate({ start: date?.start, end: date?.end, export: details.name ,index:details.monthIndex+1});
    }
    
    return (
        <div className="w-full flex flex-col h-full bg-white">
            { contextHolder }
            <div className="w-full flex items-center pl-2 pt-1 pb-2 bg-white">
                <div className="text-22">Balance Sheet</div>
            </div>
            <div className="flex pl-2 pr-2 mt-2 mb-2 justify-between items-center">
                <div className="flex items-center">
                    <div className="mr-2">Date Range:</div>
                    {
                        <RangePicker onChange={ changeDate } />
                    }
                </div>
                {/*<div>*/ }
                {/*  {*/ }
                {/*    type === 2 && <CSVLink filename={inc ?? 'csv'} data={csv}>Download me</CSVLink>*/ }
                {/*  }*/ }
                {/*</div>*/ }
            </div>
            <div className="pl-2 flex-row flex justify-between align-middle pr-5">
                <Radio.Group onChange={ changeYear } value={ value }>
                    <Radio value={ 1 }>This Year</Radio>
                    <Radio value={ 2 }>Last Year</Radio>
                </Radio.Group>
                <Button type="primary" onClick={ onHandleExport }>Export</Button>
            </div>
            {
                type === 2 && (
                    <div className="flex-1 pb-2">
                        <div className="pl-2 pr-2">
                            <div className="w-full mt-2 overflow-y-scroll relative">
                                
                                <div className="absolute top-0 left-0">
                                    <Radio.Group value={ size } onChange={ (e) => {
                                        setSize(e.target.value)
                                        
                                        setMonthType(e.target.value === 'year' ? 0 : 12);
                                    } }>
                                        <Radio.Button value="month">Month</Radio.Button>
                                        <Radio.Button value="year">Year</Radio.Button>
                                    </Radio.Group>
                                </div>
                                
                                <div className=" mr-auto ml-auto mt-5 border border-border border-solid"
                                     style={ { width: monthType === 0 ? '600px' : '1600px' } }>
                                    <div className="text-center h-28 border-b border-border border-r-0 border-l-0 border-t-0 border-solid">
                                        <input value={ inc } onChange={ (e) => {
                                            setInc(e.target.value)
                                        } } placeholder="input inc name"
                                               className="w-full text-base outline-none h-full border-none text-center" />
                                    </div>
                                    <div className="text-center text-base leading-28 border-b h-28 border-border border-r-0 border-l-0 border-t-0 border-solid">Balance
                                        Sheet
                                    </div>
                                    <div
                                        className="text-center text-base leading-28 border-b h-28 border-border border-r-0 border-l-0 border-t-0 border-solid">{ date?.start } - { date?.end }
                                    </div>
                                    
                                    <div
                                        className="w-full text-default flex items-center justify-betweens border-border pl-3 border-b border-r-0 border-l-0 border-t-0 border-solid">
                                        <div className="flex-1" />
                                        {
                                            month.slice(12 - monthType, 13).map((item, index) => {
                                                return (
                                                    <div
                                                        key={ index }
                                                        className="leading-28 pr-1 text-right border-0 border-border border-r-0 border-l border-t-0 border-solid h-28 w-100">
                                                        { item }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        testData[ 0 ].category.length > 0 && (
                                            <div className={ `w-full` }>
                                                <div
                                                    className={ `flex items-center border-border h-28 justify-between border-b border-r-0 border-l-0 border-t-0 border-solid` }>
                                                    <div className="flex-1 flex items-center">
                                                        <div className="ml-1 text-base font-bold">ASSETS</div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-full flex h-28 items-center border-border justify-between border-b border-r-0 border-l-0 border-t-0 border-solid pl-2">
                                                    <div className="text-base font-bold pl-1">{ testData[ 0 ].title }</div>
                                                    <div
                                                        className="border-0 pl-1 text-base leading-28 flex items-center border-border justify-between pr-1 border-r-0 border-l border-t-0 border-solid h-full w-100">
                                                    </div>
                                                </div>
                                                <div className="w-full overflow-hidden">
                                                    {
                                                        // @ts-ignore
                                                        testData[ 0 ].category.map((_item, _index) => {
                                                            return (
                                                                <div key={ _index }
                                                                     className="w-full text-default flex items-center justify-betweens border-border pl-3 border-b border-r-0 border-l-0 border-t-0 border-solid">
                                                                    <div className={ `flex-1 ${ _item.name.substring(3, 5) > '00' ? 'pl-2' : 'pl-1' }` }>{ _item.name }</div>
                                                                    
                                                                    {
                                                                        _item.value.slice(12 - monthType, 13).map((value: any, valueIndex: number) => {
                                                                            return (
                                                                                <div
                                                                                    key={ valueIndex }
                                                                                    onClick={ () => openDrawer(value, valueIndex) }
                                                                                    className="leading-28 hover:bg-gray-100 cursor-pointer pr-1 text-right border-0 border-border border-r-0 border-l border-t-0 border-solid h-28 w-100">
                                                                                    { value ? formatMoney(value.amount) : 0 }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                    
                                    <div
                                        className="w-full flex h-28 items-center border-border justify-between border-b border-r-0 border-l-0 border-t-0 border-solid">
                                        <div className="text-base font-bold pl-1">Total { testData[ 0 ].title }</div>
                                        
                                        <div className="flex overflow-hidden">
                                            {
                                                // @ts-ignore
                                                new Array(monthType).fill(1).map((_item, _index) => {
                                                    return (
                                                        <div key={ _index }
                                                             className="w-100 text-default flex justify-between flex border-l border-border border-r-0 border-b-0 border-t-0 border-solid items-center justify-betweens pl-1 pr-1">
                                                            <div>$</div>
                                                            <div className={ `flex-1 text-right` }>{ sumOneMonthFour(testData[ 0 ], _index) }</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div
                                                className="border-0 pl-1 text-base leading-28 flex items-center border-border justify-between pr-1 border-r-0 border-l border-t-0 border-solid h-full w-100">
                                                <div>$</div>
                                                <div>{ sumOne() }</div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div
                                        className="w-full flex h-28 items-center border-border justify-between border-b border-r-0 border-l-0 border-t-0 border-solid">
                                        <div className="text-base font-bold pl-1">LIABILITIES AND EQUITY</div>
                                        <div
                                            className="border-0 pl-1 text-base leading-28 flex items-center border-border justify-between pr-1 border-r-0 border-l border-t-0 border-solid h-full w-100">
                                        </div>
                                    </div>
                                    
                                    {
                                        expensesList.map((item, index) => {
                                            if (item.category.length > 0) {
                                                return (
                                                    <div className={ `w-full` } key={ index }>
                                                        <div
                                                            className={ `flex items-center border-border pl-2 h-28 justify-between border-b border-r-0 border-l-0 border-t-0 border-solid` }>
                                                            <div className="flex-1 flex items-center">
                                                                <div className={ `ml-1 flex-1 text-base font-bold` }>{ item.title }</div>
                                                                
                                                                <div
                                                                    className={ `border-0 pl-1 text-base leading-28 flex items-center justify-${ item.category.length === 1 ? 'between' : 'end' } border-border pr-1 border-r-0 border-l border-t-0 border-solid h-full w-100` }>
                                                                    { item.category.length === 1 && <div>$</div> }
                                                                    <div>{ item.category.length > 0 ? '0.00' : formatMoney(item.category[ 0 ].amount) }</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        {
                                                            (
                                                                <div className="w-full overflow-hidden">
                                                                    {
                                                                        // @ts-ignore
                                                                        item.category.map((_item, _index) => {
                                                                            return (
                                                                                <div key={ _item }
                                                                                     className="w-full text-default flex items-center justify-betweens border-border pl-4 border-b border-r-0 border-l-0 border-t-0 border-solid">
                                                                                    <div
                                                                                        className={ `flex-1 ${ _item.name.substring(3, 5) > '00' ? 'pl-1' : '' }` }>{ _item.name }</div>
                                                                                    
                                                                                    {
                                                                                        _item.value.slice(12 - monthType, 13).map((value: any, valueIndex: number) => {
                                                                                            return (
                                                                                                <div
                                                                                                    key={ valueIndex }
                                                                                                    onClick={ () => openDrawer(value, valueIndex) }
                                                                                                    className="leading-28 hover:bg-gray-100 cursor-pointer pr-1 text-right border-0 border-border border-r-0 border-l border-t-0 border-solid h-28 w-100">
                                                                                                    { value ? formatMoney(value.amount) : 0 }
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        
                                                        {
                                                            item.category.length > 0 && (
                                                                <div
                                                                    className="w-full flex h-28 pl-2 items-center border-border justify-between border-b border-r-0 border-l-0 border-t-0 border-solid">
                                                                    <div className="text-base font-bold pl-1">Total { item.title }</div>
                                                                    
                                                                    <div className="flex overflow-hidden">
                                                                        {
                                                                            // @ts-ignore
                                                                            new Array(monthType).fill(1).map((_item, _index) => {
                                                                                return (
                                                                                    <div key={ _index }
                                                                                         className="w-100 text-default flex justify-between flex border-l border-border border-r-0 border-b-0 border-t-0 border-solid items-center justify-betweens pl-1 pr-1">
                                                                                        <div>$</div>
                                                                                        <div className={ `flex-1 text-right` }>{ sumMonth(item, _index, index) }</div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        <div
                                                                            className="border-0 pl-1 text-base leading-28 flex items-center border-border justify-between pr-1 border-r-0 border-l border-t-0 border-solid h-full w-100">
                                                                            <div>$</div>
                                                                            <div>{ sum(item, index) }</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                    
                                    <div
                                        className="w-full flex h-28 items-center border-border justify-between border-b border-r-0 border-l-0 border-t-0 border-solid">
                                        <div className="text-base font-bold pl-1">TOTAL LIABILITIES AND EQUITY</div>
                                        
                                        <div className="flex overflow-hidden">
                                            {
                                                // @ts-ignore
                                                new Array(monthType).fill(1).map((_item, _index) => {
                                                    return (
                                                        <div key={ _index }
                                                             className="w-100 text-default flex justify-between flex border-l border-border border-r-0 border-b-0 border-t-0 border-solid items-center justify-betweens pl-1 pr-1">
                                                            <div>$</div>
                                                            <div className={ `flex-1 text-right` }>{ totalExpensesMonth(_index).toString() }</div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <div
                                                className="border-0 pl-1 text-base leading-28 flex items-center border-border justify-between pr-1 border-r-0 border-l border-t-0 border-solid h-full w-100">
                                                <div>$</div>
                                                <div>{ totalExpenses().toString() }</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            
            {
                type === 1 && (
                    <div className="w-full h-full flex items-center justify-center">
                        <div className="flex flex-col items-center justify-center">
                            <IconFont size={ 60 } name={ 'kongshuju' } />
                            <div className="mt-2 text-gray-500">No data</div>
                        </div>
                    </div>
                )
            }
            
            <Drawer title={ details.name } placement="right" width={ 1200 } onClose={ onClose } open={ open }>
                <div className="flex flex-row justify-end align-middle pb-2">
                    <Button type="primary" onClick={ onHandleExportSub }>Export</Button>
                </div>
                <Table columns={ columns } size={ 'small' } pagination={ false } dataSource={ details.details } />
                
                <div className="flex h-50 items-center justify-between">
                    <div className="pl-2">init amount</div>
                    <div className="w-100 text-left pl-2">{ details.amount && formatMoney(details.amount) }</div>
                </div>
                <div className="flex bg-gray-100 h-50 items-center justify-between">
                    <div className="pl-2">total</div>
                    <div className="w-100 text-left pl-2">{ formatMoney(totalAmount) }</div>
                </div>
            </Drawer>
        
        </div>
    )
}

export default BalanceSheet;
