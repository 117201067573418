import {Download, GET, POST} from "../request";


class PlaidApi{
    static getLinkToken(data?:{id?:string} | null){
        return GET('/v2/get_link_token',data);
    }

    static setPublicToken(data:{public_token:string,auth_info:any,id?:string | null}){
        return POST('/v2/set_public_token',data);
    }

    static transactionSync(){
        return POST('/v2/transaction_sync',null);
    }

    static getList = ({queryKey}:{queryKey:[string,{page:number;account_id:string;page_size:number;record?:0 | 1;day__lte:string | null;day__gte:string | null}]})=>{
        const data = queryKey[1];
        return GET('/v2/TradePlaid/list/', {...data,psnl:1});
    }

    static save(data?:{day__gte?:string,day__lte?:string,account_id?:string} | null){
        return POST(`/v2/trade_plaid_save`, data);
    }

    static getAccount(){
        return GET('/v2/plaid_accounts')
    }

    static queryAccount(){
        return GET('/v2/plaid_accounts')
    }
    
    static queryAccountV1(){
        return GET('v1/plaid_account_for_upload')
    }

    static getAccountAmount({queryKey}:{queryKey:[string,{page:number;page_size:number}]}){
        const data = queryKey[1];
        return GET('v2/PlaidAccount/list/', {...data,psnl:1});
    }

    static editAccountAmount(data:{id:string,amount:string}){
        return POST(`v2/PlaidAccount/operate/${data.id}/`,{amount_init:data.amount});
    }

    static delAccountAmount(data:{id:string}){
        return POST(`v2/PlaidAccount/delete/${data.id}/`,null);
    }

    static importFile(data?:{day__gte:string,day__lte:string} | null){
        return Download('/v2/plaid_export',data,true,);
    }

    static setStartDate(data:{name:string;val:string}){
        return POST('/v2/set_card_date_start',data);
    }

    static getStartDate(){
        return POST('v2/Setting/show/',{filter: {name: 'date_start'}})
    }

    static spendingAccount(_data:{number:string;balance:string}[]){
        return POST('/v2/spending_account_set',{data:_data})
    }

    static getAccountBalances(){
        return GET('/v2/PlaidAccount/list/?class_type=spending')
    }

    static queryAgreeStatus(){
        return GET('/v2/trade_plaid_need_agree')
    }

    static getPlaidUsers(){
        return GET(' v2/UserPlaid/list/');
    }

    static getFilterPlaidUsers(){
        return GET('v2/PlaidAccount/list/?serial__gt=0&enabled=1');
    }

    static updatePlaidAccount(id:string | number){
        return GET(`v2/get_link_token?id=${id}`);
    }


    static setii(){
        return POST('v2/plaid_set_need_update');
    }


    static plaidAccounts(){
        return GET('v2/PlaidAccount/list/?serial__gt=0');
    }

    static enablePlaidStatus(data:{enabled:boolean,id:string}){
        return POST('v2/PlaidAccount/operate/',data);
    }
}

export default PlaidApi;
