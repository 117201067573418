/* eslint-disable */

import React from 'react';
import { getIconColor } from './helper';

const DEFAULT_STYLE = {
  display: 'block',
};

const IconHelp = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1027 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M465.92 671.683h60.052v60.053h-60.052v-60.052z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M496.407 294.92c-39.645 0-71.528 10.665-95.67 31.928-24.098 21.308-38.633 51.952-43.492 91.98l54.203 6.435c4.68-29.88 14.558-51.862 29.587-65.903 15.053-14.063 33.795-21.105 56.25-21.105 23.242 0 42.862 7.762 58.883 23.287 16.042 15.525 24.007 33.547 24.007 54.045 0 11.363-2.745 21.803-8.19 31.365-5.468 9.607-17.348 22.41-35.595 38.52-18.293 16.132-30.915 28.395-37.958 36.788-9.562 11.542-16.515 22.973-20.813 34.267-5.85 15.053-8.797 32.918-8.797 53.617 0 3.51 0.112 8.797 0.315 15.818h50.67c0.427-21.105 1.868-36.337 4.388-45.698 2.52-9.382 6.457-17.663 11.722-24.907 5.265-7.2 16.492-18.45 33.683-33.682 25.605-22.635 42.773-41.895 51.57-57.712s13.185-33.098 13.185-51.863c0-32.4-12.51-60.053-37.508-82.912s-58.455-34.267-100.44-34.267z"
        fill={getIconColor(color, 1, '#333333')}
      />
      <path
        d="M513.823 62c-248.535 0-450 201.465-450 450 0 248.512 201.465 450 450 450 248.512 0 450-201.487 450-450 0-248.535-201.487-450-450-450zM513.823 924.493c-227.813 0-412.493-184.68-412.493-412.493s184.68-412.493 412.493-412.493c227.813 0 412.493 184.68 412.493 412.493 0 227.813-184.68 412.493-412.493 412.493z"
        fill={getIconColor(color, 2, '#333333')}
      />
    </svg>
  );
};

IconHelp.defaultProps = {
  size: 18,
};

export default IconHelp;
