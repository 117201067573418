import {GET, POST} from "../request";


export interface IUsersParams {
    page: number;
    page_size: number;
    nickname?: string;
    role?: 1 | 3 | 4;
    phone?:string;
}

export interface IUsersItem {
    avatar:string;
    create_time:string;
    email:string;
    id:number;
    name:string;
    role:number;
    role_info:string;
    update_time:string;
}

class UsersApi {
    static getList({queryKey}: { queryKey: [string, IUsersParams] }) {

        const data = queryKey[1];
        return GET('v2/user/list/',data);
    }

    static switch(data:{user_id:string}){
        return POST('v2/switch_to',data);
    }

    static switchTo(){
        return POST('v2/switch_out');
    }
}

export default UsersApi;
