import {IUserInfo} from "./api/user";


export const removerSpace = (str: string | any) => str.replace(/\s+/g, '');

export const getUserInfo = (): IUserInfo => {
    let _info: any = localStorage.getItem('userInfo');
    let info: IUserInfo = {
        avatar: '',
        create_time: '',
        email: '',
        id: null,
        name: '',
        role: null,
        role_info: '',
        update_time: ''
    };

    if (info) {
        info = JSON.parse(_info);
    }

    return info;
}


export const isCheck = (arr: any) => {
    const _arr = [...arr];
    const _list = new Set(arr);

    if (_list.size === _arr.length) {
        return true;
    } else {
        return true;
    }
}

export const formatMoney = (_num: number | string)=>{
    const amount = typeof _num === 'string' ? parseFloat(_num) : _num;

    return amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");

}

export const getFileName = (disposition: string) => {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.\S]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;
    let fileName = null;
    if (utf8FilenameRegex.test(disposition)) {
        // @ts-ignore
        fileName = decodeURIComponent(utf8FilenameRegex.exec(disposition)[1]);
    } else {
        // prevent ReDos attacks by anchoring the ascii regex to string start and // slicing off everything before 'filename='
        const filenameStart = disposition.toLowerCase().indexOf('filename=');
        if (filenameStart >= 0) {
            const partialDisposition = disposition.slice(filenameStart);
            const matches = asciiFilenameRegex.exec(partialDisposition);
            if (matches != null && matches[2]) {
                fileName = matches[2];
            }
        }
    }
    return fileName;
}


// 判断是否是本地环境
export const API_HOST = window.location.origin.includes('127.0.0.1') ? 'http://101.34.182.188:10086/' :'http://books.technologycredit.io:8890/'
