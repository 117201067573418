import React, {useState} from "react";
import {Button, DatePicker, Form, Input, message, Select} from "antd";
import IconFont from "../../components/iconfont";
import {useQuery} from "@tanstack/react-query";
import KindApi from "../../common/api/kind";
import dayjs from "dayjs";
import TradeApi from "../../common/api/trade";
import {useNavigate} from "react-router-dom";
import {AppRoutePath} from "../../routers";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {formatMoney} from "../../common/utils";


const initList = {
    // kind:'',

    debits: '',
    credits: '',

    // description:'',

    type: null,
    trade_type: null,
    kind_id: null,
    desc: '',
    // amount:_data.debit ? parseInt(_data.debit) : _data.credit * -1,
    // amount:_data.debit ? parseInt(_data.debit) : _data.credit * -1,
    // user_id:jsonUser.user_id
};

const types = [
    {value: 0, label: 'P&L'},
    {value: 1, label: 'Asset'},
    {value: 2, label: 'Liability'},
    {value: 3, label: 'Equity'},
    {value: 4, label: 'Credit Card Payment'},
];

const init = {
    day: '',
    commit: '',
    list: [initList, initList, initList],
};

export const EnterData = () => {

    const navigate = useNavigate();

    const [form, setForm] = useState(init);
    const [totals, setTotals] = useState([0.00, 0.00]);
    const [kinds, setKinds] = useState([]);

    const [verify,setVerify] = useState(false);

    const [messageApi, contextHolder] = message.useMessage();


    useQuery(['getKindList', {page: 1}], KindApi.getList, {
        onSuccess: (result) => {
            if (result.data.status === "success") {
                setKinds(result.data.data);
            } else {
                messageApi.error(result.data.msg);
            }
        }
    })

    const add = () => {
        const _form = {...form};
        const _list = _form.list;

        _list.push(initList);

        setForm({...form, list: _list});

    }

    const remove = (index: number) => {
        const _form = {...form};
        const _list = _form.list;


        _list.splice(index, 1);

        setForm({...form, list: _list});

    }

    const changeForm = (event: React.ChangeEvent<HTMLInputElement>, index: number, type: 'type' | 'debits' | 'credits' | 'desc' | 'kind') => {
        const value = event.target.value;

        const _form = {...form};
        const _list = [..._form.list];

        const ojb = {..._list[index]};

        // @ts-ignore
        ojb[type] = value;

        _list[index] = ojb;

        setForm({...form, list: _list});

        const _total = [0.00, 0.00];

        _list.forEach((item) => {
            if (item.debits) {
                console.log('12312');
                _total[0] = _total[0] + parseFloat(item.debits);
            }

            if (item.credits) {
                _total[1] = _total[1] + parseFloat(item.credits);
            }
        })
        setTotals(_total);

    }

    const renderData = (_type: string | number) => {
        let _list: number[] = [];
        const _arr: any = [];

        switch (_type) {

            // P&L
            case 0:
                _list = [3, 4, 5, 6, 7, 8, 9];
                break;
            // Asset
            case 1:
                _list = [1];
                break;
            // Liability
            case 2:
                _list = [2];
                break;
            // Equity
            case 3:
                _list = [3];
                break;
            case 4:
                _list = [2];
                break;
            default:
                break;
        }


        if (_type != 4) {
            _list.forEach((item: number) => {
                kinds.forEach((_item: any) => {
                    if (_item.group === item) {
                        _arr.push({value: _item.id.toString(), label: _item.name});
                    }
                })
            })

            return _arr;
        } else {
            _list.forEach((item: number) => {
                kinds.forEach((_item: any) => {
                    if (_item.group === 2) {
                        if (_item.id == '31') {
                            _arr.push({value: _item.id.toString(), label: _item.name});
                        }
                    }
                })
            })

            return _arr;
        }
    }


    const submit = () => {

        if (!form.day){
            setVerify(true);
            return false;
        }

        const jsonUser: any = JSON.parse(localStorage.getItem('userInfo') || '{}');

        let obj: any = {
            day: dayjs(form.day).format('YYYY-MM-DD'),
            user_id: jsonUser.id
        };

        form.list.forEach((item) => {

            if (item.type != null) {
                obj = {
                    ...obj,
                    // @ts-ignore
                    type: item.type,
                    trade_type: parseFloat(item.debits) > 0 ? 0 : 1,
                    kind_id: item.kind_id,
                    desc: item.desc,
                    manual:1,
                    amount: item.debits ? parseFloat(item.debits) : parseFloat(item.credits) * -1,
                }

                TradeApi.create(obj).then((result: any) => {
                    console.log(result);
                    if (result.status === 200) {
                        if (result.data.code === '200') {
                            messageApi.success('success');

                            navigate(AppRoutePath.transactions);
                        }
                    } else {
                        messageApi.error(result.data.msg);
                    }
                });
            }
        })

    }

    return (
        <div className='w-full'>
            {contextHolder}
            <div className='flex items-center justify-between'>
                <div className='flex mb-1 items-center'>
                    {/*<div className='text-sm mr-2 text-default'>date</div>*/}
                    <div className='relative'>
                    <DatePicker
                        status={verify ? 'error' : ''}
                        className='w-200'
                        onChange={(v, s) => {
                            setVerify(false);
                            setForm({...form, day: s});
                        }}
                    />
                        {
                            verify && (
                                <div className='flex absolute text-s text-error' style={{bottom:'-20px'}}>
                                    <ExclamationCircleOutlined className='mr-1' />
                                    Please select a date
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>

            <div className='border mt-2 border-solid'>
                {
                    form.list.map((item, index) => {
                        if (index === 0) {
                            return (
                                <div className='flex text-m items-center' key={index}>
                                    <div
                                        className='w-50 flex items-center pl-2 border-b border-r border-t-0 border-l-0 border-solid h-34'></div>
                                    <div
                                        className='w-180 flex items-center pl-2 border-b border-r border-t-0 border-l-0 border-solid h-34'>Type
                                    </div>
                                    <div
                                        className='w-290 flex items-center pl-2 border-b border-r border-t-0 border-l-0 border-solid h-34'></div>
                                    <div
                                        className='w-150 flex items-center pl-2 border-b border-r border-t-0 border-l-0 border-solid h-34'>DEBITS
                                    </div>
                                    <div
                                        className='w-150 flex items-center pl-2 border-b border-r border-t-0 border-l-0 border-solid h-34'>CREDITS
                                    </div>
                                    <div
                                        className='flex-1 flex items-center pl-2 border-b border-r border-t-0 border-l-0 border-solid h-34'>DESCRIPTION
                                    </div>
                                    <div
                                        className='w-70 flex items-center pl-2 border-b border-r-0 border-t-0 border-l-0 border-solid h-34'></div>
                                </div>
                            )
                        } else {

                            return (
                                <div className='flex text-m items-center' key={index}>
                                    <div
                                        className='w-50 flex items-center pl-2 border-b border-r border-t-0 border-l-0 border-solid h-34'>{index}</div>
                                    <div
                                        className='w-180 flex items-center border-b border-r border-t-0 border-l-0 border-solid h-34'>
                                        <Select
                                            bordered={false}
                                            style={{width: '100%'}}
                                            placeholder='select type'
                                            onChange={(value, option) => {

                                                const _form = {...form};
                                                const _list = [..._form.list];

                                                const ojb = {..._list[index]};

                                                ojb.type = value;

                                                _list[index] = ojb;

                                                setForm({...form, list: _list});
                                            }}
                                            options={types}
                                        />
                                    </div>
                                    <div
                                        className='w-290 flex items-center border-b border-r border-t-0 border-l-0 border-solid h-34'>
                                        <Select
                                            style={{width: '100%'}}
                                            bordered={false}
                                            disabled={item.type === null}
                                            options={renderData(item.type || 0)}
                                            onChange={(value) => {
                                                const _form = {...form};
                                                const _list = [..._form.list];

                                                const ojb = {..._list[index]};

                                                ojb.kind_id = value;

                                                _list[index] = ojb;

                                                setForm({...form, list: _list});
                                            }}
                                        />
                                    </div>
                                    <div
                                        className='w-150 flex items-center border-b border-r border-t-0 border-l-0 border-solid h-34'>
                                        <input onChange={(e) => changeForm(e, index, 'debits')}
                                               value={item ? item.debits : ''}
                                               className='w-full h-full border-none pl-2'/>
                                    </div>
                                    <div
                                        className='w-150 flex items-center border-b border-r border-t-0 border-l-0 border-solid h-34'>
                                        <input onChange={(e) => changeForm(e, index, 'credits')}
                                               value={item ? item.credits : ''}
                                               className='w-full h-full border-none pl-2'/>
                                    </div>

                                    <div
                                        className='flex-1 flex items-center border-b border-r border-t-0 border-l-0 border-solid h-34s'>
                                        <textarea onChange={(e: any) => changeForm(e, index, 'desc')}
                                                  value={item ? item.desc : ''}
                                                  className='w-full h-full border-none pl-2'/>
                                    </div>
                                    <div onClick={() => remove(index)}
                                         className='w-70 flex items-center justify-center border-b border-r-0 border-t-0 border-l-0 border-solid h-34'>
                                        <IconFont name={'shanchu'}/>
                                    </div>
                                </div>
                            )
                        }
                    })
                }

                <div className='flex text-m items-center'>
                    <div className='w-50 flex items-center pl-2 h-34'></div>
                    <div className='w-180 flex items-center justify-end pr-2 h-34'>Total</div>
                    <div className='w-290 flex items-center pl-2 h-34'></div>
                    <div className='w-200 flex items-center pl-2 h-34'>{formatMoney(totals[0])}</div>
                    <div className='w-200 flex items-center pl-2 h-34'>{formatMoney(totals[1])}</div>
                    <div className='flex-1 flex items-center pl-2 h-34'></div>
                    <div className='w-70 flex items-center pl-2 h-34'></div>
                </div>
            </div>

            <div className='flex mt-2 items-center justify-between'>
                <div>
                    <Button onClick={add} type='link'>add</Button>
                </div>
                <div>
                    <Button onClick={submit} type={'primary'}>confirm</Button>
                </div>
            </div>

        </div>
    )
}

