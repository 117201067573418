/* eslint-disable */

import React from 'react';
import { getIconColor } from './helper';

const DEFAULT_STYLE = {
  display: 'block',
};

const IconQianbi = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1025 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M511.206708 149.166927c199.687988 0 362.134165 162.446178 362.134166 362.134165S710.894696 873.335413 511.206708 873.335413 149.072543 710.889236 149.072543 511.201248s162.446178-362.034321 362.134165-362.034321m0-42.633386c-223.550702 0-404.667707 181.216849-404.667706 404.667707s181.216849 404.667707 404.667706 404.667707 404.667707-181.216849 404.667707-404.667707-181.117005-404.667707-404.667707-404.667707z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M511.206708 408.361934c11.382215 0 22.065523 4.393136 30.152886 12.4805l60.205929 60.205928c16.574103 16.574103 16.574103 43.631825 0 60.205928L541.359594 601.659906c-8.087363 8.087363-18.770671 12.480499-30.152886 12.4805-11.382215 0-22.065523-4.393136-30.152886-12.4805L420.847894 541.354134c-16.574103-16.574103-16.574103-43.631825 0-60.205928l60.205928-60.205928c8.087363-8.087363 18.770671-12.580343 30.152886-12.580344m0-42.533541c-21.765991 0-43.631825 8.287051-60.205928 24.960999l-60.205928 60.205928c-33.24805 33.24805-33.24805 87.263651 0 120.5117l60.205928 60.205929c16.673947 16.673947 38.439938 24.960998 60.205928 24.960998 21.765991 0 43.631825-8.287051 60.205929-24.960998l60.205928-60.205929c33.24805-33.24805 33.24805-87.263651 0-120.5117l-60.205928-60.205928c-16.574103-16.673947-38.439938-24.960998-60.205929-24.960999z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconQianbi.defaultProps = {
  size: 18,
};

export default IconQianbi;
