import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {BrowserRouter} from "react-router-dom";
import App from "./routers";
import 'antd/dist/reset.css';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import history from "./routers/history";

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


root.render(
  <QueryClientProvider client={queryClient}>
      <BrowserRouter >
        <App/>
      </BrowserRouter>
  </QueryClientProvider>
);
