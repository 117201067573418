/* eslint-disable */

import React from 'react';
import { getIconColor } from './helper';

const DEFAULT_STYLE = {
  display: 'block',
};

const IconJinggao = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M512 956.951273c245.76 0 444.951273-199.214545 444.951273-444.951273 0-245.76-199.214545-444.951273-444.951273-444.951273-245.76 0-444.951273 199.214545-444.951273 444.951273 0 245.76 199.214545 444.951273 444.951273 444.951273zM512 1024C229.236364 1024 0 794.763636 0 512S229.236364 0 512 0s512 229.236364 512 512-229.236364 512-512 512z"
        fill={getIconColor(color, 0, '#888888')}
      />
      <path
        d="M512 640c23.552 0 42.658909-21.224727 42.658909-47.406545v-331.869091c0-26.158545-19.083636-47.383273-42.658909-47.383273-23.552 0-42.658909 21.224727-42.658909 47.383273v331.869091c0 26.181818 19.083636 47.406545 42.658909 47.406545zM512 725.341091a42.682182 42.682182 0 1 0 0 85.341091 42.682182 42.682182 0 0 0 0-85.341091z"
        fill={getIconColor(color, 1, '#888888')}
      />
    </svg>
  );
};

IconJinggao.defaultProps = {
  size: 18,
};

export default IconJinggao;
