import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {Button, Form, Input, Modal, Popconfirm, Table, Select, message, Tag} from "antd";
import RuleApi from "../../common/api/rule";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {getUserInfo} from "../../common/utils";
import {IUserInfo} from "../../common/api/user";
import KindApi from "../../common/api/kind";
import IconFont from "../../components/iconfont";

const Rule = () => {

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [open, setOpen] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [list, setList] = useState([]);
    const [rules, setRules] = useState([]);
    const [data, setData] = useState(['content']);
    const [editData, setEditData] = useState<any>({content: []});
    const [messageApi, contextHolder] = message.useMessage();
    const [userInfo, setUserInfo] = useState({rule: 0, id: null});
    const [queryType, setQueryType] = useState(0);

    useEffect(() => {
        const jsonUser: any = localStorage.getItem('userInfo');


        if (jsonUser) {
            const userData = JSON.parse(jsonUser);

            console.log('---------')
            console.log(userData)
            console.log('---------')


            setUserInfo({rule: userData.role, id: userData.id});
            // setUserInfo({rule: 0, id: userData.id});
        }

    }, [])

    useQuery(['getKindList', {page: 1}], KindApi.getList, {
        onSuccess: (result) => {
            if (result.data.status === "success") {

                const _list = result.data.data.map((item: any, index: number) => {
                    return {value: item.id, key: index, label: item.name}
                })

                setList(_list)
            } else {
                messageApi.error(result.data.msg);
            }
        }
    })

    useQuery(['getRuleList', {
        page: 1,
        user_id: userInfo.rule === 1 ? null : queryType == 1 ? null : userInfo.id,
        user_id__gt: queryType == 0 ? 0 : null,
        is_global: queryType == 1 ? 1 : null
    }], RuleApi.getList, {
        enabled: userInfo.id != null,
        onSuccess: (result) => {
            if (result.data.status === "success") {
                console.log(result.data.data);
                setRules(result.data.data);
            } else {
                messageApi.error(result.data.msg);
            }
        }
    })

    const createRule = useMutation(RuleApi.create, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                setOpen(false);
                queryClient.prefetchQuery(['getRuleList', {
                    page: 1,
                    user_id: userInfo.rule === 1 ? null : userInfo.id,
                    user_id__gt: queryType == 0 ? 0 : null,
                    is_global: queryType == 1 ? 1 : null
                }])
            } else {
                messageApi.error(result.data.msg);
            }
        }
    });

    const updateRule = useMutation(RuleApi.update, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                setOpenEdit(false);
                queryClient.prefetchQuery(['getRuleList', {
                    page: 1,
                    user_id: userInfo.rule === 1 ? null : userInfo.id,
                    user_id__gt: queryType == 0 ? 0 : null,
                    is_global: queryType == 1 ? 1 : null
                }])
            } else {
                messageApi.error(result.data.msg);
            }
        }
    });

    const removeRule = useMutation(RuleApi.remove, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                setOpen(false);
                queryClient.prefetchQuery(['getRuleList', {
                    page: 1,
                    user_id: userInfo.rule === 1 ? null : userInfo.id,
                    user_id__gt: queryType == 0 ? 0 : null,
                    is_global: queryType == 1 ? 1 : null
                }])
            } else {
                messageApi.error(result.data.msg);
            }
        }
    });

    const columns = [
        {
            title: 'rule',
            dataIndex: 'kind',
            key: 'rule',
            sorter: (a:any, b:any) =>{
                const _a:any = list.find((it:any)=>it.value === a.kind);
                const _b:any = list.find((it:any)=>it.value === b.kind);
                return  parseInt(_a?.label) - parseInt(_b?.label)
            },
            render: (item: any, index: any) => {
                return <div>{list.map((_item: any) => {
                    if (_item.value === item) {
                        return <div key={'1' + _item.kind_id}>{_item.label}</div>
                    }
                })}</div>
            },
        },
        {
            title: 'content',
            dataIndex: 'content',
            key: 'content',
            render:(item:any,value:any)=>{
                return (
                    <div>{item.join(',')}</div>
                )
            }
        },
        {
            title: 'update date',
            dataIndex: 'update_time',
            key: 'update_time',
        },
        {
            title: 'create date',
            dataIndex: 'create_time',
            key: 'create_time',
        },
        {
            title: 'action',
            width: 300,
            render: (item: any) => {

                if (queryType === 1 && userInfo.rule !== 1) {
                    return null
                }

                return (
                    <div>
                        <Button onClick={() => edit(item)} type={"link"}>edit</Button>
                        {
                            userInfo.rule === 1 && queryType === 0 && (
                                <Button onClick={() => saveGlobal(item)} type={"link"}>set global</Button>)
                        }
                        <Button danger onClick={() => remove(item)} type={"link"}>delete</Button>
                    </div>
                )
            }
        },
    ];

    const submit = (data: any) => {
        const _data = {...data};
        delete _data.kind_id;
        const _list = [];

        for (const listKey in _data) {
            _list.push(_data[listKey]);
        }

        console.log(_list);


        createRule.mutate({kind_id: data.kind_id, content: _list})
    }

    const saveGlobal = (data: any) => {
        updateRule.mutate({id: data.id, user_id: 0, kind_id: data.kind, content: data.content});
    }

    const submitEdit = (data: any) => {
        const _data = {...data};
        delete _data.kind_id;
        const _list = [];

        for (const listKey in _data) {
            _list.push(_data[listKey]);
        }


        updateRule.mutate({id: editData.id, kind_id: data.kind_id, content: _list});
    }

    const remove = (data: any) => {
        removeRule.mutate(data.id);
    }

    const edit = (data: any) => {
        setOpenEdit(true);
        setEditData({
            ...data,
            content: data.content
        });
    }

    const add = () => {
        const _list = [...data];
        _list.push(`content${(_list.length + 1).toString()}`)
        setData(_list);
    }

    const deleteData = (index: number) => {
        const _list = [...data];
        _list.splice(index, 1);

        setData(_list);
    }

    const addEdit = () => {
        const _list = [...editData.content];
        _list.push(``)
        setEditData({...editData, content: _list});
    }

    const deleteEditData = (index: number) => {
        const _list = [...editData.content];
        _list.splice(index, 1);

        setEditData({...editData, content: _list});
    }


    return (
        <div className='w-full h-full'>
            {contextHolder}
            <div>
                <div className='w-full pl-2 pr-2 pt-2 flex items-center justify-between'>

                    <Select
                        defaultValue={queryType}
                        style={{width: 120}}
                        options={[
                            {value: 1, label: 'admin'},
                            {value: 0, label: 'user'},
                        ]}
                        onChange={(value) => {
                            setQueryType(value);
                        }}
                    />


                    <Button onClick={() => setOpen(true)}>Add Rule</Button>
                </div>
                <div className='pl-2 mt-2 pr-2'>
                    <Table dataSource={rules} columns={columns}/>
                </div>
            </div>

            <Modal
                title='Add Rule'
                open={open}
                width={400}
                footer={null}
                destroyOnClose={true}
                onCancel={() => {
                    setData(['content'])
                    setOpen(false);
                }}
            >
                <Form className='mt-3' layout="vertical" onFinish={submit}>
                    <Form.Item labelCol={{span: 9}} label='Chart of Account'
                               rules={[{required: true, message: 'Please select your rule!'}]}
                               name='kind_id'>
                        <Select
                            defaultValue=""
                            options={list}
                        />
                    </Form.Item>
                    {
                        data.map((item, index) => {
                            return (
                                <Form.Item key={item} initialValue={''} labelCol={{span: 6}} label='Rule'
                                           rules={[{required: true, message: `Please input your ${item}!`}]}
                                           name={item}>
                                    <Input prefix={
                                        <div className='relatives'>
                                            <Popconfirm
                                                placement="leftBottom"
                                                title={'example'}
                                                showCancel={false}
                                                description={() => {
                                                    return (
                                                        <div>
                                                            <div className='mt-1'>
                                                                <div>Fill in the first line：<Tag
                                                                    color="blue">CREDIT</Tag></div>
                                                            </div>
                                                            <div className='mt-1'>
                                                                <div>Fill in the second line：<Tag
                                                                    color="orange">AUTOPAYBUS</Tag></div>
                                                            </div>
                                                            <div className='bg-gray-100 mt-2 p-1'>
                                                                <div>
                                                                    CHASE <Tag color="#108ee9">CREDIT</Tag> CRD <Tag
                                                                    color="#108ee9">AUTOPAYBUS</Tag> XXXXXXXX
                                                                </div>
                                                                <div className='mt-1 text-warning'>The matching data
                                                                    will be classified as the selected
                                                                    rule
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                                }}
                                            >
                                                <div className='w-20 h-20 absolute' style={{top: '-29px', left: 50}}>
                                                    <IconFont name={'help'}/>
                                                </div>
                                            </Popconfirm>
                                        </div>
                                    } suffix={
                                        <div className='relatives'>
                                            {
                                                (data.length - 1) === index && (
                                                    <Button style={{width:'40px'}} type={'link'} onClick={add}>add</Button>
                                                )

                                            }
                                            {
                                                data.length != 1 && (
                                                    <Button style={{width:'60px'}} type={'link'} danger
                                                            onClick={() => deleteData(index)}>delete</Button>
                                                )
                                            }

                                        </div>
                                    }/>
                                </Form.Item>
                            )
                        })
                    }

                    <Form.Item>
                        <div className='w-full flex items-center justify-end'>
                            <Button onClick={() => {
                                setData(['content'])
                                setOpen(false)
                            }}>取消</Button>
                            <Button htmlType={'submit'} type={'primary'} className='ml-2'>添加</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                title='edit Rule'
                open={openEdit}
                width={400}
                footer={null}
                destroyOnClose={true}
                onCancel={() => {
                    setData(['content'])
                    setOpenEdit(false);
                }}
            >
                <Form className='mt-3' layout="vertical" onFinish={submitEdit}>
                    <Form.Item labelCol={{span: 9}} initialValue={editData.kind} label='Chart of Account'
                               rules={[{required: true, message: 'Please select your rule!'}]}
                               name='kind_id'>

                        <Select
                            options={list}
                        />
                    </Form.Item>
                    {
                        editData?.content.map((item: any, index: any) => {
                            return (
                                <Form.Item key={index} initialValue={item} labelCol={{span: 6}} label='Rule'
                                           rules={[{required: true, message: `Please input your content!`}]}
                                           name={`content${index}`}>
                                    <Input
                                        prefix={
                                            <div className='relatives'>
                                                <Popconfirm
                                                    placement="leftBottom"
                                                    title={'example'}
                                                    showCancel={false}
                                                    description={() => {
                                                        return (
                                                            <div>
                                                                <div className='mt-1'>
                                                                    <div>Fill in the first line：<Tag
                                                                        color="blue">CREDIT</Tag></div>
                                                                </div>
                                                                <div className='mt-1'>
                                                                    <div>Fill in the second line：<Tag
                                                                        color="orange">AUTOPAYBUS</Tag></div>
                                                                </div>
                                                                <div className='bg-gray-100 mt-2 p-1'>
                                                                    <div>
                                                                        CHASE <Tag color="#108ee9">CREDIT</Tag> CRD <Tag
                                                                        color="#108ee9">AUTOPAYBUS</Tag> XXXXXXXX
                                                                    </div>
                                                                    <div className='mt-1 text-warning'>The matching data
                                                                        will be classified as the selected
                                                                        rule
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    }}
                                                >
                                                    <div className='w-20 h-20 absolute' style={{top: '-29px', left: 50}}>
                                                        <IconFont name={'help'}/>
                                                    </div>
                                                </Popconfirm>
                                            </div>
                                        } suffix={
                                        <div className='relatives'>
                                            {
                                                (data.length - 1) === index ? (
                                                    <Button type={'link'} onClick={addEdit}>add</Button>
                                                ) : (
                                                    <Button type={'link'} danger
                                                            onClick={() => deleteEditData(index)}>delete</Button>
                                                )
                                            }
                                        </div>
                                    }/>
                                </Form.Item>
                            )
                        })
                    }

                    <Form.Item>
                        <div className='w-full flex items-center justify-end'>
                            <Button onClick={() => {
                                setData(['content'])
                                setOpenEdit(false)
                            }}>取消</Button>
                            <Button htmlType={'submit'} type={'primary'} className='ml-2'>添加</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default Rule;
