import {GET, POST} from "../request";

export interface IUserInfo {
  avatar: string;
  create_time: string;
  email: string;
  id: number | string | null;
  name: string;
  role: number | null;
  role_info: string;
  update_time: string;
}

interface ICreateUser {
  name:string;
  email:string;
  password:string;
}


class User {
  static async login(data: { email: '', password: '' }) {
    const result = await POST('/v1/login', data);
    return result;
  }

  static async create(data:ICreateUser){
    const result = await POST('/v1/register', data);
    return result;
  }

  static async bankTrade(data:{
    day: string;
    hash: number;
    desc: number;
    amount_b: number;
    amount: string | number;
    amount_n: string | number;
  }){
    const result = await POST('v2/BankTrade/operate/', data);
    return result;
  }

  static async setVal(data:{
   name:'debit' | 'credit';
   val:string;
  }){
    const result = await POST('v2/set_card_amount', data);
    return result;
  }

  static getInfo(){
    return GET('/v2/user_info');
  }

}

export default User;
