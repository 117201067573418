import React from 'react';
import {Button} from "antd";
import {AppRoutePath} from "../../routers";
import {useNavigate} from "react-router-dom";

const SelectType = ()=>{

    const navigate = useNavigate();

    return (
        <div className='p-2'>
            <div className='h-50'></div>
            <Button onClick={()=>{
                navigate(AppRoutePath.uploadCSV)
            }
            } className='mr-2' type={'primary'}>Upload Bank Csv</Button>
            <Button onClick={()=>{
                navigate(AppRoutePath.plaid)
            }
            } className='mr-2' type={'primary'}>Link plaid</Button>
        </div>
    )
}

export default SelectType;