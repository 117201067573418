import {GET, POST} from "../request";

interface ICardCreate {
  user_id:string | number | null;
  name:string;
  id?:string;
  col:string;
}

class CardApi{
  static getCards({queryKey}:any){
    const data = queryKey[1];
    console.log(data);
    return GET('/v2/Card/list/',{page:1,page_size:99999},true);
  }

  static create(data:ICardCreate){
    return POST('/v2/Card/operate/',data,true);
  }

  static upDate(data:ICardCreate){
    console.log('--------')
    console.log('--------')
    console.log(data);
    console.log('--------')
    console.log('--------')

    return POST('/v2/Card/operate/',data,true);
  }

  static remove(id:string){
    return POST(`/v2/Card/delete/${id}`,null,true);
  }
}

export default CardApi;
