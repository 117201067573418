import React, {useState} from 'react';
// @ts-ignore
import LoginBg from "../../assets/images/home_login_bg.svg";
import {Button, Form, Input, message} from "antd";
import {useNavigate} from "react-router-dom";
import {RequiredMark} from "antd/es/form/Form";
import {useMutation} from "@tanstack/react-query";
import User from "../../common/api/user";
import {initRequestToken} from "../../common/request";
import {AppRoutePath} from "../../routers";
import {removerSpace} from "../../common/utils";

const Register = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [requiredMark, setRequiredMarkType] = useState<RequiredMark>('optional');
  const [passwrod, setPasswrod] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');


  const loginApi = useMutation(User.create, {
    onSuccess: (result) => {
      if (result.data.status === "success") {
        messageApi.success(result.data.msg);
        // initRequestToken(result.data.token);
        // localStorage.setItem('userInfo', JSON.stringify(result.data.data));
        setTimeout(() => {
          navigate(AppRoutePath.login,{replace:true});
        }, 1000)

      } else {
        messageApi.error(result.data.msg);
      }
    }
  })

  const onSubmit = async (data: any) => {

    const email = removerSpace(data.email);
    const password = removerSpace(data.password);

    const name = removerSpace(data.name);

    loginApi.mutate({name: name, email: email, password: password});

  };

  return (
    <div className='w-full h-full bg-white'>
      {contextHolder}
      <div className='flex w-full h-full items-center justify-center pr-4'>
        <div className='bg-white shadow rounded p-3 w-400 pt-2 pb-4'>
          <div className='text-22 pt-2 pb-2'>Create Account</div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            autoComplete="off"
          >
            <Form.Item label="name" className='login-input'
                       rules={[{required: true, message: 'Please input your name!'}]} name="name">
              <Input placeholder='Please input your name'/>
            </Form.Item>

            <Form.Item label="email" className='login-input'
                       rules={[{required: true, message: 'Please input your email!'}]} name="email">
              <Input placeholder='Please input your email'/>
            </Form.Item>

            <Form.Item label="password" className='login-input'
                       rules={[{required: true, message: 'Please input your password!'}]} name="password">
              <Input.Password onChange={(e) => {
                setPasswrod(e.target.value)
              }} placeholder='Please input your password'/>
            </Form.Item>

            <Form.Item label="confirm password" className='login-input' rules={[{
              transform: (value) => {
                if (value !== passwrod) {
                  return true;
                } else {
                  return null;
                }

              }, message: 'Please input your confirm password!'
            }]} name="confirm_password">
              <Input.Password onChange={(e) => setConfirmPassword(e.target.value)}
                              placeholder='Please input your confirm password'/>
            </Form.Item>

            <Form.Item className='mt-5' labelCol={{span: 2}}>
              <Button type="primary" loading={loginApi.isLoading} className='max-w-full w-full' style={{width: '100%'}}
                      htmlType="submit">Submit</Button>
            </Form.Item>
          </Form>

        </div>
      </div>
    </div>
  )
}

export default Register;
