import React, {useEffect} from "react";
import {RouteObject, useLocation, useNavigate, useRoutes} from "react-router-dom";
import Login from "../pages/login";
import BookQuick from "../pages/home/table";
import UploadCSV from "../pages/uploadCSV";
import Home from "../pages/home";
import Trade from "../pages/transactions";
import Profit from "../pages/profit";
import Card from "../pages/user/card";
import Kind from "../pages/user/kind";
import Rule from "../pages/user/rule";
import Register from "../pages/login/register";
import ProfitLoss from "../pages/transactions/profit_loss";
import Settings from "../pages/settings";
import BalanceSheet from "../pages/transactions/balance_sheet";
import BankStatement from "../pages/bankStatement";
import JournalEntry from "../pages/transactions/journalEntry";
import Users from "../pages/users";
import {useQuery} from "@tanstack/react-query";
import User from "../common/api/user";
import SyncPlaid from "../pages/uploadCSV/plaid";
import SelectType from "../pages/uploadCSV/selectType";


export const AppRoutePath = {
  login: '/login',
  register: '/register',
  transactions: '/',
  uploadCSV:'/uploadCSV',
  uploadSelect:'/uploadSelect',
  plaid:'/plaid',
  profit:'/profit',
  trade:'/trade',
  card:'/card',
  kind:'/kind',
  rule:'/rule',
  journalEntry:'/journalEntry',
  balanceSheet:'/balanceSheet',
  bankStatement:'/bankStatement',
  profitLoss:'/profitLoss',
  settings:'/settings',
  users:'/users',
}

const indexRouter: RouteObject[] = [
  {
    path: AppRoutePath.login,
    index: true,
    element: <Login/>
  },
  {
    path: AppRoutePath.register,
    index: true,
    element: <Register/>
  },
  {
    path: AppRoutePath.transactions,
    element: <Home/>,
    children:[
      {
        path:AppRoutePath.transactions,
        element:<Trade/>,
        index:true
      },
      {
        path:AppRoutePath.profitLoss,
        element:<ProfitLoss/>,
      },
      {
        path:AppRoutePath.uploadSelect,
        element:<SelectType/>
      },
      {
        path:AppRoutePath.uploadCSV,
        element:<UploadCSV/>
      },
      {
        path:AppRoutePath.profit,
        element:<Profit/>
      },
      {
        path:AppRoutePath.plaid,
        element:<SyncPlaid/>
      },
      {
        path:AppRoutePath.card,
        element:<Card/>
      },
      {
        path:AppRoutePath.kind,
        element:<Kind/>
      },

      {
        path:AppRoutePath.rule,
        element:<Rule/>
      },
      {
        path:AppRoutePath.balanceSheet,
        element:<BalanceSheet/>

      },
      {
        path:AppRoutePath.bankStatement,
        element:<BankStatement/>

      },{
        path:AppRoutePath.journalEntry,
        element:<JournalEntry/>

      },
      {
        path:AppRoutePath.settings,
        element:<Settings/>
      },
      {
        path:AppRoutePath.users,
        element:<Users/>
      }
    ]
  },

]


const App = () => {
  const element = useRoutes(indexRouter);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (location.pathname !== AppRoutePath.login){
      if (!token){
        navigate(AppRoutePath.login);
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
      }
    }
  }, [])


  return (
    <div className='w-full h-full'>
      {element}
    </div>
  )
}

export default App;
