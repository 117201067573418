/* eslint-disable */

import React from 'react';
import { getIconColor } from './helper';

const DEFAULT_STYLE = {
  display: 'block',
};

const IconYinhangka = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M838.12754 256.524641c31.578413 0 57.260857 25.682444 57.260857 57.260857v397.128526c0 31.578413-25.682444 57.260857-57.260857 57.260856H185.173895c-31.578413 0-57.260857-25.682444-57.260857-57.260856V313.785498c0-31.578413 25.682444-57.260857 57.260857-57.260857H838.12754m0-42.67083H185.173895c-55.162291 0-99.931687 44.769396-99.931688 99.931687v397.128526c0 55.162291 44.769396 99.931687 99.931688 99.931687H838.12754c55.162291 0 99.931687-44.769396 99.931687-99.931687V313.785498c0-55.162291-44.769396-99.931687-99.931687-99.931687z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M106.627589 384.437201h810.146189v42.670831H106.627589z"
        fill={getIconColor(color, 1, '#333333')}
      />
      <path
        d="M767.475837 640.262321c-12.791256 0-24.183468 5.796038-31.97814 14.689958 6.595491 7.494877 10.692691 17.18825 10.69269 27.980872s-4.097199 20.386064-10.69269 27.980873c7.794672 8.89392 19.186884 14.689958 31.97814 14.689958 23.583878 0 42.67083-19.086952 42.67083-42.670831s-19.086952-42.67083-42.67083-42.67083zM703.519557 640.262321c-23.583878 0-42.67083 19.086952-42.670831 42.67083s19.086952 42.67083 42.670831 42.670831c12.791256 0 24.183468-5.796038 31.97814-14.689958-6.595491-7.494877-10.692691-17.18825-10.692691-27.980873s4.097199-20.386064 10.692691-27.980872c-7.794672-8.89392-19.186884-14.689958-31.97814-14.689958z"
        fill={getIconColor(color, 2, '#333333')}
      />
    </svg>
  );
};

IconYinhangka.defaultProps = {
  size: 18,
};

export default IconYinhangka;
