import React, {useEffect, useState} from "react";
import {Button, Form, Input, message, Modal, Popconfirm, Select, Table} from "antd";
import IconFont from "../../components/iconfont";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import CardApi from "../../common/api/card";
import {getUserInfo} from "../../common/utils";




const Card = () => {

  const queryClient = useQueryClient();
  const [open,setOpen] = useState<boolean>(false);
  const [list,setList] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [selects,setSelects] = useState<string[]>(['','','','','']);
  const [selectOptions,setSelectOptions] = useState<any[]>([]);
  const [editData,setEditData] = useState<any>({name:'',col:['','','','','']});
  const [isUpDate,setIsUpDate] = useState(false);

  useEffect(()=>{
    const _list = [[0,1,2,3,4,5,6,7,8],[0,1,2,3,4,5,6,7,8],[0,1,2,3,4,5,6,7,8],[0,1,2,3,4,5,6,7,8],[0,1,2,3,4,5,6,7,8]];

    const arr:any = _list.map((item)=>{
      return item.map((_item)=>{
        return {value:_item,label:_item};
      })
    })

    setSelectOptions(arr);
  },[])

  useQuery(['getCardList',{page:1}],CardApi.getCards,{
    onSuccess:(result)=>{
      if (result.data.status === 'success'){
        setList(result.data.data);
      }else{
        messageApi.error(result.data.msg);
      }
    }
  })

  const create = useMutation(CardApi.create,{
    onSuccess:(result)=>{
        console.log(result);

        if (result.data.status === 'success'){
          setOpen(false);
          queryClient.refetchQueries(['getCardList',{page:1}]);
          messageApi.success('create card success!');
        }else{
          messageApi.error(result.data.msg);
        }
    }
  })

  const upDate = useMutation(CardApi.upDate,{
    onSuccess:(result)=>{
      if (result.data.status === 'success'){
        setOpen(false);
        queryClient.refetchQueries(['getCardList',{page:1}]);
        messageApi.success('update card success!');
      }else{
        messageApi.error(result.data.msg);
      }
    }
  })

  const remove = useMutation(CardApi.remove,{
    onSuccess:(result)=>{
      if(result.data.status === 'success'){
        messageApi.success('remove card success!');
        queryClient.refetchQueries(['getCardList',{page:1}]);
      }else{
        messageApi.error(result.data.msg);
      }
    }
  })

  const deleteData = (data:any)=>{

    console.log(data);

    remove.mutate(data.id);
  }

  const editCard = (data:any)=>{
    setEditData({name:data.name,col:data.col.split(','),id:data.id});
    setIsUpDate(true);
    setOpen(true);
  }

  const columns = [
    {
      title: 'name',
      dataIndex: 'name',
      width: 300,
      key: 'name',
    },
    {
      title: 'col',
      dataIndex: 'col',
      key: 'col',
    },
    {
      title: 'update date',
      width: 300,
      dataIndex: 'update_time',
      key: 'update_time',
    },
    {
      title: 'create date',
      width: 300,
      dataIndex: 'create_time',
      key: 'create_time',
    },
    {
      title: 'action',
      width: 300,
      key: 'address',
      render:(item:any)=>{
        return (
          <div>
            <Button type={'link'} onClick={()=>editCard(item)}>edit</Button>
            <Popconfirm
              title={'tips'}
              description={'Delete this data！'}
              onConfirm={()=>deleteData(item)}
              okText="Yes"
              cancelText="No"
            >
            <Button danger type={'link'}>delete</Button>
            </Popconfirm>
          </div>
        )
      }
    },
  ];

  const submit = (data:any)=>{
    const {id} = getUserInfo();

    const _data:any = {name:data.name,col:`${data.details},${data.date},${data.description},${data.amount},${data.type}`};

    if (isUpDate){
      _data.id = editData.id;
      upDate.mutate({user_id:id,id:_data.id,name:_data.name,col:_data.col})
    }else{
      create.mutate({user_id:id,name:_data.name,col:_data.col})
    }

  }



  const changeOptions = (value:string,index:number)=>{
    const _list = [...selects];
    _list[index] = value;

    setSelects(_list);
  }

  return (
    <div className='w-full h-full'>
      {contextHolder}
      <div className='w-full'>
        <div className='ml-2 mt-2'><Button onClick={()=>setOpen(true)}>Add Account</Button></div>
        <div className='w-full pl-2 pr-2 mt-2'>
          <Table dataSource={list} columns={columns}/>
        </div>
      </div>

      {
        open && (
          <Modal
            title='Add Account'
            open={open}
            width={400}
            footer={null}
            forceRender={true}
            onCancel={()=>{
              setEditData({name:'',col:['','','','','']});
              setOpen(false);
              setIsUpDate(false);
            }}
          >
            <Form className='mt-3' onFinish={submit}>
              <Form.Item labelCol={{span:7}} initialValue={editData.name} rules={[{ required: true, message: 'Please input your name!' }]} label='name' name='name'>
                <Input/>
              </Form.Item>
              <Form.Item labelCol={{span:7}} initialValue={editData.col[0]} label='Details' rules={[{ required: true, message: 'Please select Details position!' }]} name='details'>
                <Select options={selectOptions[0]} onChange={(value)=>changeOptions(value,0)}/>
              </Form.Item>
              <Form.Item labelCol={{span:7}} initialValue={editData.col[1]} label='Posting Date' rules={[{ required: true, message: 'Please select Posting Date position!' }]} name='date'>
                <Select options={selectOptions[1]} onChange={(value)=>changeOptions(value,1)}/>
              </Form.Item>
              <Form.Item labelCol={{span:7}} initialValue={editData.col[2]} label='Description' rules={[{ required: true, message: 'Please select Description position!' }]} name='description'>
                <Select options={selectOptions[2]} onChange={(value)=>changeOptions(value,2)}/>
              </Form.Item>
              <Form.Item labelCol={{span:7}} initialValue={editData.col[3]} label='Amount' rules={[{ required: true, message: 'Please select Amount position!' }]} name='amount'>
                <Select options={selectOptions[3]} onChange={(value)=>changeOptions(value,3)}/>
              </Form.Item>
              <Form.Item labelCol={{span:7}} initialValue={editData.col[4]} label='Type' rules={[{ required: true, message: 'Please select Type position!' }]} name='type'>
                <Select options={selectOptions[4]} onChange={(value)=>changeOptions(value,4)}/>
              </Form.Item>
              <Form.Item>
                <div className='w-full flex items-center justify-end'>
                  <Button onClick={()=>{
                    setOpen(false)
                    setEditData({name:'',col:['','','','','']});
                    setIsUpDate(false)
                  }}>取消</Button>
                  <Button htmlType={'submit'} type={'primary'} className='ml-2'>添加</Button>
                </div>
              </Form.Item>
            </Form>
          </Modal>
        )
      }

    </div>
  )
}

export default Card;
