/* eslint-disable */

import React from 'react';
import { getIconColor } from './helper';

const DEFAULT_STYLE = {
  display: 'block',
};

const IconH = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M511.987 902.209c-49.499 0-97.577-9.494-142.794-28.32-43.71-18.103-82.907-44.048-116.591-77.104-33.688-33.066-60.125-71.585-78.621-114.451-19.13-44.378-28.837-91.53-28.837-140.145 0-58.162 14.532-115.909 41.994-167.062 26.551-49.425 65.122-92.823 111.567-125.494 12.904-9.077 30.833-6.134 40.056 6.5 9.223 12.638 6.28 30.204-6.629 39.226-39.248 27.683-71.824 64.268-94.207 105.994-23.493 43.732-35.393 91.108-35.393 140.837 0 81.139 32.197 157.426 90.668 214.783 58.433 57.379 136.143 88.981 218.789 88.981 82.624 0 160.356-31.603 218.825-88.981 58.465-57.355 90.637-133.643 90.637-214.783 0-49.673-11.873-97.133-35.393-140.837-22.388-41.692-54.986-78.309-94.244-105.938-12.873-9.018-15.874-26.588-6.62-39.221 9.25-12.634 27.147-15.604 40.024-6.5 46.473 32.665 85.099 76.063 111.627 125.489 27.486 51.154 42.018 108.904 42.018 167.066 0 48.609-9.704 95.762-28.862 140.171-18.473 42.866-44.933 81.386-78.616 114.446-33.693 33.061-72.892 58.973-116.596 77.165-45.244 18.712-93.294 28.178-142.797 28.178v0zM520.609 511.54c-15.848 0-28.691-12.55-28.691-28.128v-333.465c0-15.521 12.845-28.156 28.691-28.156 15.848 0 28.719 12.634 28.719 28.156v333.465c0 15.577-12.873 28.128-28.719 28.128v0z"
        fill={getIconColor(color, 0, '#333333')}
      />
    </svg>
  );
};

IconH.defaultProps = {
  size: 18,
};

export default IconH;
