/* eslint-disable */

import React from 'react';
import IconJinggao from './IconJinggao';
import IconShanchu from './IconShanchu';
import IconHelp from './IconHelp';
import IconKongshuju from './IconKongshuju';
import IconH from './IconH';
import IconGengduo from './IconGengduo';
import IconQianbi from './IconQianbi';
import IconTianjia from './IconTianjia';
import IconYinhangka from './IconYinhangka';
export { default as IconJinggao } from './IconJinggao';
export { default as IconShanchu } from './IconShanchu';
export { default as IconHelp } from './IconHelp';
export { default as IconKongshuju } from './IconKongshuju';
export { default as IconH } from './IconH';
export { default as IconGengduo } from './IconGengduo';
export { default as IconQianbi } from './IconQianbi';
export { default as IconTianjia } from './IconTianjia';
export { default as IconYinhangka } from './IconYinhangka';

const IconFont = ({ name, ...rest }) => {
  switch (name) {
    case 'jinggao':
      return <IconJinggao {...rest} />;
    case 'shanchu':
      return <IconShanchu {...rest} />;
    case 'help':
      return <IconHelp {...rest} />;
    case 'kongshuju':
      return <IconKongshuju {...rest} />;
    case 'h':
      return <IconH {...rest} />;
    case 'gengduo':
      return <IconGengduo {...rest} />;
    case 'qianbi':
      return <IconQianbi {...rest} />;
    case 'tianjia':
      return <IconTianjia {...rest} />;
    case 'yinhangka':
      return <IconYinhangka {...rest} />;

  }

  return null;
};

export default IconFont;
