import React, {useContext, useEffect, useRef, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Button, Form, Input, message, Modal, Pagination, Popconfirm, Select, Table,DatePicker, Tag, Tooltip} from "antd";
import RuleApi from "../../../common/api/rule";
import {ColumnsType} from "antd/es/table";
import KindApi from "../../../common/api/kind";
import TradeApi from "../../../common/api/trade";
import IconFont from "../../../components/iconfont";
import {FormInstance} from "antd/es/form";
import {formatMoney} from "../../../common/utils";

const { RangePicker } = DatePicker;


interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
    tag:any
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

const types = [
    {value: 0, label: 'P&L'},
    {value: 1, label: 'Asset'},
    {value: 2, label: 'Liability'},
    {value: 3, label: 'Equity'},
    {value: 4, label: 'Credit Card Payment'},
];

const BookedEntries = ()=>{
    const queryClient = useQueryClient();
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [open, setOpen] = useState<boolean>(false);
    const [openTradeType, setOpenTradeType] = useState<boolean>(false);
    const [tradeType, setTradeType] = useState<number | null>(null);
    const [data, setData] = useState(['content']);
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [messageApi, contextHolder] = message.useMessage();
    const [kinds, setKinds] = useState([]);
    const [kindList, setKindList] = useState([]);
    const [type, setType] = useState(0);
    const [descBy, setDescBy] = useState<'descend' | 'ascend' | null>(null);
    const [amountBy, setAmountBy] = useState<'descend' | 'ascend' | null>(null);
    const [typeBy, setTypeBy] = useState<'descend' | 'ascend' | null>(null);
    const [sortedInfo, setSortedInfo] = useState<any>({});
    const [selecteData, setSelecteData] = useState([]);
    const [userInfo, setUserInfo] = useState({rule: 0, id: null});
    const [rules, setRules] = useState([]);

    useEffect(() => {
        getList(pageIndex);

    }, [pageSize,pageIndex])

    useEffect(() => {
        const jsonUser: any = localStorage.getItem('userInfo');


        if (jsonUser) {
            const userData = JSON.parse(jsonUser);

            setUserInfo({rule: userData.role, id: userData.id});
        }

    }, [])

    useEffect(() => {
        getList(pageIndex);
    }, [descBy, amountBy, typeBy])

    const add = () => {
        const _list = [...data];
        _list.push(`content${(_list.length + 1).toString()}`)
        setData(_list);
    }

    const deleteData = (index: number) => {
        const _list = [...data];
        _list.splice(index, 1);

        setData(_list);
    }

    const createRule = useMutation(RuleApi.create, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                setOpen(false);
                queryClient.prefetchQuery(['getRuleList', {
                    page: 1,
                    user_id: userInfo.rule === 1 ? null : userInfo.id
                }])
            } else {
                messageApi.error(result.data.msg);
            }
        }
    });

    const submit = (data: any) => {
        const _data = {...data};
        delete _data.kind_id;
        const _list = [];

        for (const listKey in _data) {
            _list.push(_data[listKey]);
        }



        createRule.mutate({kind_id: data.kind_id, content: _list})
    }


    const tables: ColumnsType<DataType> = [
        {title: 'Date', dataIndex: 'day', key: 'date', width: 120,fixed: 'left'},
        {
            title: 'Description',
            dataIndex: 'desc',
            key: 'description',
            showSorterTooltip: false,
            sorter: true,
            fixed: 'left',
            width:300,
            sortOrder: sortedInfo.columnKey === 'description' ? descBy : null
        },
        {
            title:'Plaid',
            dataIndex: 'desc1',
            width:100,
            key: 'description2',
            render: (value, record) => {
                // @ts-ignore
                const manual = record?.manual;
                // @ts-ignore
                const is_bank = record?.is_bank;
                
                return (
                    <Tag className='absolute' style={{top: 0, right: 0}} color="blue">{ record.tag }</Tag>
                )
                
                // return (
                //     <div className='relative flex items-center'>
                //         {manual === 1 && is_bank && <Tag className='absolute' style={{top: 0, right: 0}} color="blue">Entry</Tag>}
                //
                //         {manual === 2 && is_bank && <Tag className='absolute' style={{top: 0, right: 0}} color="blue">Plaid Bank</Tag>}
                //         {manual === 2 && !is_bank && <Tag className='absolute' style={{top: 0, right: 0}} color="blue">Plaid Card</Tag>}
                //
                //         {manual === 0 && is_bank && <Tag className='absolute' style={{top: 0, right: 0}} color="blue">Bank</Tag>}
                //         {manual === 0 && !is_bank && <Tag className='absolute' style={{top: 0, right: 0}} color="blue">Card</Tag>}
                //     </div>
                // )
            }
        },

        {
            title: 'Type',
            // @ts-ignore
            sorter: true,
            showSorterTooltip: false,
            dataIndex: 'type', key: 'type', width: 184,
            sortOrder: sortedInfo.columnKey === 'type' ? typeBy : null,
            render:(value,_data)=>{
                return (
                    <div className="editable-cell-value-wrap" style={{paddingRight: 24}}>
                        {
                            types.map((item) => {
                                if (item.value === value) {
                                    return (<div>{item.label}</div>)
                                }
                            })
                        }
                    </div>
                )
            }
        },
        {
            title: 'Trade Type',
            dataIndex: 'trade_type_info',
            key: 'trade_type_info',
            width:120
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 200,
            showSorterTooltip: false,
            sorter: true,
            sortOrder: sortedInfo.columnKey === 'amount' ? amountBy : null,
            render: (item, _data: any) => {
                return (
                    <Tooltip title={_data?.trade_type_info}>
                        <div className='w-full flex h-full'>
                            <div className='flex-1'>{_data.trade_type_info === 'Spending' && formatMoney(Math.abs(item))}</div>
                            <div style={{width: '1px', margin: '-9px 0', background: '#F0F0F0'}}><span
                                style={{height: 'calc(100% + 16px)'}} className='opacity-0'>|</span></div>
                            <div className='flex-1'><span className='ml-2'/>{_data.trade_type_info === 'Receiving' && formatMoney(Math.abs(item))}</div>
                        </div>
                    </Tooltip>
                )
            }
        },
        {
            title: 'Category',
            dataIndex: 'kind',
            width: 250,
            render: (item) => {

                console.log(item);

                return <div>{item ? kinds.map((_item: any) => {
                    if (_item.id === item) {
                        return <div key={_item.id}>{_item.name ?? 'select'}</div>
                    }

                }) : <div className='text-primary'>-</div>}</div>
            }
        }
    ];

    const columns = tables.map((col: any) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: DataType) => {
                return {
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave,
                    typeSave
                };
            },
        };
    });

    const rowSelection: any = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        onSelect: (record: any, selected: any, selectedRows: any) => {
            console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected: any, selectedRows: any, changeRows: any) => {
            console.log(selected, selectedRows, changeRows);
        },
    };

    const EditableCell: React.FC<any> = ({
                                             title,
                                             editable,
                                             children,
                                             dataIndex,
                                             record,
                                             handleSave,
                                             typeSave,
                                             ...restProps
                                         }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef<any>(null);
        const [value, setValue] = useState('');
        const [typeValue, setTypesValue] = useState(0);
        const form = useContext(EditableContext)!;

        useEffect(() => {
            if (editing) {
                inputRef.current!.focus();
            }
        }, [editing]);

        const toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({[dataIndex]: record[dataIndex]});
        };

        const save = async () => {
            try {
                toggleEdit();

                if (title === 'Type') {
                    if (typeValue) {
                        typeSave({...record, type: typeValue})
                    }
                } else {
                    if (value) {
                        handleSave({...record, kind: value});
                    }
                }

            } catch (errInfo) {
                console.log('Save failed:', errInfo);
            }
        };

        let childNode = children;

        const renderData = (_type: number) => {
            let _list: number[] = [];
            const _arr: any = [];


            switch (_type) {

                // P&L
                case 0:
                    _list = [3,4,5,6,7,8,9];
                    break;
                // Asset
                case 1:
                    _list = [1];
                    break;
                // Liability
                case 2:
                    _list = [2];
                    break;
                // Equity
                case 3:
                    _list = [3];
            }

            _list.forEach((item: number) => {
                kinds.forEach((_item: any) => {
                    if (_item.group === item) {
                        _arr.push({value: _item.id.toString(), label: _item.name});
                    }
                })
            })

            console.log(_arr);

            return _arr;
        }

        const handleChange = (_value: string) => {
            setValue(_value);
        };

        const handleTypeChange = (_value: string) => {

            setTypesValue(parseInt(_value));
        };

        if (title === 'Type') {
            if (editable) {
                childNode = editing ? (
                    <Select className='w-full' defaultValue={types[record.type].label} options={types} ref={inputRef}
                            onChange={handleTypeChange} onBlur={save}/>
                ) : (
                    <div className="editable-cell-value-wrap" style={{paddingRight: 24}} onClick={toggleEdit}>
                        {
                            types.map((item) => {
                                if (item.value === record.type) {
                                    return (<div>{item.label}</div>)
                                }
                            })
                        }
                    </div>
                );
            }
        } else {
            if (editable) {
                childNode = editing ? (
                    <Select className='w-full' defaultValue={'Select Category'} options={renderData(record.type)}
                            ref={inputRef}
                            onChange={handleChange} onBlur={save}/>
                ) : (
                    <div className="editable-cell-value-wrap" style={{paddingRight: 24}} onClick={toggleEdit}>
                        {children}
                    </div>
                );
            }
        }


        return <td {...restProps}>{childNode}</td>;
    };


    const EditableRow: React.FC<any> = ({index, ...props}) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };

    const components = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const handleSave = (row: any) => {
        row.kind_id = parseInt(row.kind);
        row.fixed = true;
        row.user_id = row.user;
        delete row.user;
        delete row.kind;
        delete row.transaction_info;
        editListData.mutate(row);
    };

    const typeSave = (row: any) => {
        row.fixed = true;
        row.user_id = row.user;
        delete row.user;
        delete row.kind;
        delete row.transaction_info;

        editListData.mutate(row);
    }

    useEffect(() => {
        getList(pageIndex);
        getTypes();
    }, [])

    useQuery(['getKindList', {page: 1}], KindApi.getList, {
        onSuccess: (result) => {
            if (result.data.status === "success") {
                // @ts-ignore
                setKinds(result.data.data)

                const _list = result.data.data.map((item: any, index: number) => {
                    return {value: item.id, key: index, label: item.name}
                })

                setKindList(_list)
            } else {
                messageApi.error(result.data.msg);
            }
        }
    })

    const editListData = useMutation(TradeApi.editData, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                getList(1);
                setSelecteData([]);
            } else {
                messageApi.error(result.data.msg);
            }
        }
    });

    const recordListData = useMutation(TradeApi.editData, {
        onSuccess: (result) => {
            setOpenTradeType(false);
            setTradeType(null);
            if (result.data.status === 'success') {
                getList(1);
                setSelecteData([]);
                messageApi.success('record data success!');
            } else {
                messageApi.error(result.data.msg);
            }
        }
    });

    const delListData = useMutation(TradeApi.delData, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                getList(1);
                setSelecteData([]);
                messageApi.success('delete data success!');
            } else {
                messageApi.error(result.data.msg);
            }

            setTimeout(()=>{
                queryClient.refetchQueries(['getNumber']);
                queryClient.prefetchQuery(['getNumberRecord'])

            },500)

        }
    });

    const filter = (value: any, close: () => void) => {

        setType(value.id);
        if (value.id === 0) {
            getList(pageIndex);
        } else {
            getList(pageIndex, value.id);
        }

        close();

    }

    const getList = async (index: number, kind_id?: string | number,date?:string[]) => {
        const result = await TradeApi.getListRecord({
            page: index,
            kind_id: kind_id,
            pageSize: pageSize,
            date:date,
            order: [descBy, amountBy, typeBy]
        });

        console.log(result);
        if (result.data.status === 'success') {
            setList(result.data.data);
            setTotal(result.data.limit);
        } else {
            messageApi.error(result.data.msg);
        }
    }

    const getTypes = async () => {
        // const result = await KindApi.getList();
        // console.log(result);
    }

    const changePage = (index: number) => {
        setPageIndex(index);
    }

    const record = () => {
        let _list: any = [...selecteData];

        _list = _list.map((item: number) => {
            return {id: item, record: false};
        });
        recordListData.mutate({multi_items: _list} as any);
    }

    const delList = () => {
        let _list: any = [...selecteData];

        _list = _list.map((item: number) => {
            return item.toString();
        });

        delListData.mutate({id: _list});
    }

    const submitType = ()=>{
        const data:any[] = [...selecteData];
        const _list:any = [];
        list.forEach((item:any)=>{

            data.map((_item)=>{
                if(_item === item.id){
                    const _data = item;
                    delete _data.user;
                    delete _data.kind;
                    _data.trade_type = tradeType;
                    _list.push(_data);
                }
            })

        })

        recordListData.mutate({multi_items:_list} as any);
    }

    return (
        <div className='w-full flex flex-col h-full bg-white'>
            {contextHolder}
            <div className='w-full flex items-center justify-between pl-2 pr-2 pt-1 pb-2 bg-white'>
                <div className='text-22'>Booked Entries</div>
                <RangePicker
                onChange={(item,s)=>{
                    getList(pageIndex,undefined,s);
                }}
                />
            </div>
            <div className='flex-1'>
                <div className='pl-2 pr-2' style={{width: '100%', height: '70vh'}}>
                    <Table
                        columns={columns as any}
                        bordered
                        components={components}
                        size={'small'}
                        style={{height: '70vh'}}
                        scroll={{y: '70vh'}}
                        pagination={false}
                        rowKey={'id'}
                        rowSelection={{
                            checkStrictly: false,
                            selectedRowKeys: selecteData,
                            onChange: (v, r) => {
                                setSelecteData(v as any);
                            },
                        }}
                        onChange={(pagination, filters, sorter: any) => {
                            setSortedInfo(sorter);
                            if (sorter.field === "amount") {
                                if (sorter.order === "ascend") {
                                    setAmountBy('ascend');
                                } else if (sorter.order === "descend") {
                                    setAmountBy('descend');
                                } else {
                                    setAmountBy(null);
                                }
                            }

                            if (sorter.field === "type") {
                                if (sorter.order === "ascend") {
                                    setTypeBy('ascend');
                                } else if (sorter.order === "descend") {
                                    setTypeBy('descend');
                                } else {
                                    setTypeBy(null);
                                }
                            }

                            if (sorter.field === "desc") {
                                if (sorter.order === "ascend") {
                                    setDescBy('ascend');
                                } else if (sorter.order === "descend") {
                                    setDescBy('descend');
                                } else {
                                    setDescBy(null);
                                }
                            }
                        }}
                        dataSource={list}
                    />
                </div>
                <div className='w-full mt-6 pl-2 pr-2 flex items-center justify-between'>
                    <div>
                        <Button type={'primary'} disabled={selecteData.length === 0} onClick={record}>REVOKE</Button>
                        <Button className='ml-2' type={'primary'} danger disabled={selecteData.length === 0}
                                onClick={delList}>DELETE</Button>
                    </div>
                    <Pagination current={pageIndex}
                                total={total}
                                pageSize={pageSize}
                                pageSizeOptions={['10', '20', '50', total.toString()]}
                                size={'small'}
                                showLessItems={true}
                                onShowSizeChange={(current, size) => {
                                    setPageIndex(current);
                                    setPageSize(isNaN(size) ? total : size);
                                }}
                                onChange={changePage}/>
                </div>
            </div>

            <Modal
                title='set trade type'
                open={openTradeType}
                width={400}
                footer={null}
                destroyOnClose={true}
                onCancel={() => {
                    setOpenTradeType(false);
                }}
            >
                <div className='w-full'>
                    <div className='mb-1'>trade type</div>
                    <Select defaultValue={0} options={[
                        {key: '01', value: 0, label: 'consumption'},
                        {key: '02', value: 1,label:'income'},
                        {key: '03', value: 2,label:'repayment'}]} className='w-full'
                            onChange={(value)=>{
                                setTradeType(value)
                            }}
                    />
                </div>
                <div className='flex items-center mt-2 justify-end'>
                    <Button className='mr-2' onClick={() => {
                        setOpenTradeType(false);
                    }}>cancel</Button>
                    <Button onClick={submitType}>confirm</Button>
                </div>
            </Modal>

            <Modal
                title='Add Rule'
                open={open}
                width={400}
                footer={null}
                destroyOnClose={true}
                onCancel={() => {
                    setData(['content'])
                    setOpen(false);
                }}
            >
                <Form className='mt-3' layout="vertical" onFinish={submit}>
                    <Form.Item labelCol={{span: 9}} label='Chart of Account'
                               rules={[{required: true, message: 'Please select your rule!'}]}
                               name='kind_id'>
                        <Select
                            defaultValue=""
                            options={kindList}
                        />
                    </Form.Item>
                    {
                        data.map((item, index) => {
                            return (
                                <Form.Item key={item} initialValue={''} labelCol={{span: 6}} label='Rule'
                                           rules={[{required: true, message: `Please input your ${item}!`}]}
                                           name={item}>
                                    <Input prefix={
                                        <div className='relatives'>
                                            <Popconfirm
                                                placement="leftBottom"
                                                title={'example'}
                                                showCancel={false}
                                                description={() => {
                                                    return (
                                                        <div>
                                                            <div className='mt-1'>
                                                                <div>Fill in the first line：<Tag
                                                                    color="blue">CREDIT</Tag></div>
                                                            </div>
                                                            <div className='mt-1'>
                                                                <div>Fill in the second line：<Tag
                                                                    color="orange">AUTOPAYBUS</Tag></div>
                                                            </div>
                                                            <div className='bg-gray-100 mt-2 p-1'>
                                                                <div>
                                                                    CHASE <Tag color="#108ee9">CREDIT</Tag> CRD <Tag
                                                                    color="#108ee9">AUTOPAYBUS</Tag> XXXXXXXX
                                                                </div>
                                                                <div className='mt-1 text-warning'>The matching data
                                                                    will be classified as the selected
                                                                    rule
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                                }}
                                            >
                                                <div className='w-20 h-20 absolute' style={{top: '-29px', left: 50}}>
                                                    <IconFont name={'help'}/>
                                                </div>
                                            </Popconfirm>
                                        </div>
                                    } suffix={
                                        <div className='relatives'>
                                            {
                                                (data.length - 1) === index && (
                                                    <Button type={'link'} onClick={add}>add</Button>
                                                )
                                            }
                                            {
                                                data.length != 1 && (
                                                    <Button type={'link'} danger
                                                            onClick={() => deleteData(index)}>delete</Button>
                                                )
                                            }

                                        </div>
                                    }/>
                                </Form.Item>
                            )
                        })
                    }

                    <Form.Item>
                        <div className='w-full flex items-center justify-end'>
                            <Button onClick={() => {
                                setData(['content'])
                                setOpen(false)
                            }}>取消</Button>
                            <Button htmlType={'submit'} type={'primary'} className='ml-2'>添加</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>

        </div>
    )
}

export default BookedEntries;
