import React, {useEffect, useState} from "react";
import {Layout, Menu, Dropdown, Button, Modal, Input} from "antd";
import {useLocation, useNavigate} from "react-router-dom";
import {AppRoutePath} from "../../routers";
import {ItemType} from "antd/es/menu/hooks/useItems";
import type {MenuProps} from 'antd';
import IconFont from "../iconfont";
import TradeApi from "../../common/api/trade";
import {useMutation, useQuery} from "@tanstack/react-query";
import User from "../../common/api/user";
import dayjs from "dayjs";
import BankTradeApi from "../../common/api/bankTrade";
import UsersApi from "../../common/api/UsersApi";

const {Header, Content, Footer, Sider} = Layout;

interface IBqLayout {
    children: React.ReactElement;
}


const BqLayout = (props: IBqLayout) => {

    const {children} = props;
    const location = useLocation();
    const [path, setPath] = useState<string>('');
    const navigate = useNavigate();
    const [number, setNumber] = useState(0);
    const [showBalance, setShowBalance] = useState(false);
    const [accountBalance, setAccountBalance] = useState<string>('');
    const [userInfo, setUserInfo] = useState<any>();
    const [menus, setMenus] = useState<ItemType[]>([]);


    // useQuery(['getNumber'], TradeApi.getNumber, {
    //     onSuccess: (res) => {
    //         if (res.data.limit > 0) {
    //             setNumber(res.data.limit);
    //         }
    //     }
    // })


    useEffect(() => {
        setPath(location.pathname);
    }, [location])

    useEffect(() => {
        if (!showBalance) {
            setAccountBalance('');
        }
    }, [showBalance])

    const items: ItemType[] = [
        {
            key: '/transactions_page',
            type: 'group',
            label: 'Transactions',
            children: [
                {
                    key: '/',
                    icon: '',
                    label: `Categorizing ${number ? `(${number})` : ''}`,
                    onClick: () => {
                        navigate(AppRoutePath.transactions);
                    }
                },
                // {
                //     key: '/bank statement',
                //     icon: '',
                //     label: `Bank Statement`,
                //     onClick: () => {
                //         navigate(AppRoutePath.bankStatement);
                //     }
                // },
                {
                    key: '/newEntry',
                    icon: '',
                    label: 'New Journal Entry',
                    onClick: () => {
                        navigate(AppRoutePath.journalEntry);
                    }
                },
                {
                    key: '/reviewEntry',
                    icon: '',
                    label: 'Review Journal Entry',
                    onClick: () => {
                        // navigate(AppRoutePath.transactions);
                    }
                },
                {
                    key: '/reconciliation',
                    icon: '',
                    label: 'Reconciliation',
                    onClick: () => {
                        // navigate(AppRoutePath.transactions);
                    }
                },
            ]
            // onClick:()=>{
            //   navigate(AppRoutePath.transactions);
            // }
        },
        {
            key: '/reporting',
            label: 'Reporting',
            type: 'group',
            children: [
                {
                    key: '/balanceSheet',
                    icon: '',
                    label: 'Balance Sheet',
                    onClick: () => {
                        navigate(AppRoutePath.balanceSheet);
                    }
                },
                {
                    key: AppRoutePath.profitLoss,
                    icon: '',
                    label: 'Profit / Loss',
                    onClick: () => {
                        navigate(AppRoutePath.profitLoss);
                    }
                }
            ]
        },
        {
            key: '/settings',
            icon: '',
            label: 'Settings',
            onClick: () => {
                navigate(AppRoutePath.settings);
            }
        }
    ];

    useEffect(() => {


        const user = localStorage.getItem('userInfo');

        if (user) {
            const _userInfo = JSON.parse(user);


            if (_userInfo.role === 1) {
                items.push(
                    {
                        key: '/users',
                        icon: '',
                        label: 'Users',
                        onClick: () => {
                            navigate(AppRoutePath.users)
                        }
                    }
                )

            }

            setMenus(items);
            setUserInfo(_userInfo);
        }


    }, []);


    const menu: ItemType[] = [
        {
            key: '3',
            label: (
                <div className='w-200 h-40 flex items-center'>
                    <div><IconFont size={24} name={'h'}/></div>
                    <div className='ml-2'>{userInfo?.can_switch_out ? 'Switch out User' : 'logout'}</div>
                </div>
            ),
            onClick: () => {
                if (userInfo.can_switch_out) {
                    logout.mutate();

                } else {
                    localStorage.removeItem('token');
                    localStorage.removeItem('userInfo');
                    sessionStorage.removeItem('auth');
                    navigate(AppRoutePath.login, {replace: true});
                }
            }
        }
    ];

    const getUser = useMutation(User.getInfo, {
        onSuccess: (result) => {
            localStorage.setItem('userInfo', JSON.stringify(result.data.data));
            window.location.reload();
        }
    });

    const logout = useMutation(UsersApi.switchTo, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                getUser.mutate();
            }
        }
    })

    const balanceApi = useMutation(User.bankTrade, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                setShowBalance(false);
            }
        }
    })

    const setBalance = () => {
        setShowBalance(true);
    }

    const submitBalance = () => {

        balanceApi.mutate({
            day: dayjs(new Date()).format('YYYY-MM-DD'),
            hash: 0,
            desc: 0,
            amount_b: 0,
            amount: accountBalance,
            amount_n: accountBalance,
        })
    }

    return (
        <Layout className='w-full h-full flex'>
            <Sider style={{background: '#494E53'}} width={240}>
                <div style={{height: 32, margin: 16}}/>
                <div className='w-full box-border pr-1 pl-1'>
                    {/*<Dropdown menu={{ items:[ {*/}
                    {/*        key: '1',*/}
                    {/*        label: (*/}
                    {/*            <div onClick={()=>{*/}
                    {/*                navigate(AppRoutePath.uploadCSV);*/}
                    {/*            }}>*/}
                    {/*                Upload Bank/Credit Card*/}
                    {/*            </div>*/}
                    {/*        ),*/}
                    {/*    },*/}
                    {/*        {*/}
                    {/*            key: '2',*/}
                    {/*            label: (*/}
                    {/*                <div onClick={()=>{*/}
                    {/*                    navigate(AppRoutePath.plaid);*/}
                    {/*                }}>*/}
                    {/*                    Sync Plaid*/}
                    {/*                </div>*/}
                    {/*            ),*/}
                    {/*        },] }} placement="bottomLeft" arrow>*/}
                    {/*    /!*<Button>bottomLeft</Button>*!/*/}
                    {/*    <Button onClick={() => {*/}
                    
                    {/*    }} ghost={true} className='w-full'>upload csv</Button>*/}
                    {/*</Dropdown>*/}

                        <Button onClick={() => {
                            navigate(AppRoutePath.uploadSelect);
                        }} ghost={true} className='w-full'>Upload Transactions</Button>

                </div>
                <div className='w-full box-border pr-1 mt-2 pl-1'>
                    <Menu theme="dark" style={{background: '#494E53'}} selectedKeys={[path]} mode="inline"
                          items={menus}/>
                </div>
            </Sider>
            <Layout className="site-layout">
                <Header className='flex items-center justify-between' style={{height: '40px', background: '#FFF'}}>
                    <div>
                        {/*<HomeOutlined />*/}
                    </div>
                    <div>
                        <Dropdown menu={{items: menu}} placement="bottomLeft" arrow>
                            <div className='flex h-40 items-center'>
                                <div className='w-24 h-24 rounded-full relative'>
                                    <div
                                        className='w-full h-full rounded-full overflow-hidden flex items-center justify-center'>
                                        <img width={24} height={24}
                                             src={require("../../assets/images/avatar.png")}/>
                                    </div>
                                    {
                                        userInfo?.can_switch_out && (
                                            <div style={{right: 0, bottom: 0}}
                                                 className='absolute w-10 rounded-full h-10 bg-primary'></div>
                                        )
                                    }
                                </div>
                                <div className='ml-1 text-gray'>{userInfo?.name || ''}</div>
                            </div>
                        </Dropdown>
                    </div>
                </Header>
                <Content className='flex flex-col'>
                    <div className='flex-1 overflow-y-scroll bg-white'>
                        {children}
                    </div>
                </Content>
                {/*<Footer style={{padding:'6px 0',height:'auto',textAlign: 'center'}}>Ant Design ©2023 Created by Ant UED</Footer>*/}
            </Layout>
            <Modal open={showBalance} title={'Balance'} footer={null} onCancel={() => {
                setShowBalance(false)
            }}>
                <div className='flex flex-col'>
                    <div className='flex-1'>
                        <div>set account Balance</div>
                        <Input value={accountBalance} onChange={(e) => {
                            setAccountBalance(e.target.value);
                        }} placeholder={'Please enter the balance'}/>
                    </div>
                    <div className='flex mt-2 items-center justify-end'>
                        <Button onClick={submitBalance} type={'primary'}>ok</Button>
                    </div>
                </div>
            </Modal>
        </Layout>
    )
}

export default BqLayout;
