import React, {useState} from "react";
import PlaidClassList from "../uploadCSV/List";
import {Button, message, Pagination, Table, Tabs} from "antd";
import {useMutation, useQuery} from "@tanstack/react-query";
import UsersApi, {IUsersItem} from "../../common/api/UsersApi";
import User from "../../common/api/user";
import dayjs from "dayjs";


const Users = () => {

    const [messageApi, contextHolder] = message.useMessage();
    const [list, setList] = useState<IUsersItem[]>([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    const columns = [
        {
            title: 'name',
            dataIndex: 'name',
            width: 250,
            key: 'name',
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'role',
            width: 300,
            dataIndex: 'role_info'
        },
        {
            title: 'action',
            width: 200,
            render: (data: any) => {
                return (
                    <Button onClick={() => {
                        switchApi.mutate({
                            user_id: data.id
                        })
                    }} type={'link'}>switch User</Button>
                )
            }
        }
    ];


    const switchApi = useMutation(UsersApi.switch, {
        onSuccess: (reseult) => {
            if (reseult.data.status === 'success') {
                getUser.mutate();
            }else{
                messageApi.error(reseult.data.msg);
            }
        }
    })



    const getUser = useMutation(User.getInfo,{
        onSuccess:(result)=>{
            localStorage.setItem('userInfo',JSON.stringify(result.data.data));
            window.location.href = '/';
        }
    });

    useQuery(['get_users_list', {page: pageIndex, page_size: pageSize, role: 4}], UsersApi.getList, {
        onSuccess: (result) => {
            if (result.data.data.length > 0) {
                setList(result.data.data);
            } else {
                setList([]);
            }

            setTotal(result.data.limit);
        }
    })

    return (
        <div className='w-full h-full bg-white relative'>
            {contextHolder}
            <div className='w-full flex items-center pl-2 pt-1 pb-2 bg-white'>
                <div className='text-22'>Users</div>
            </div>
            <div className='flex flex-col w-full pl-2 pr-2 mt-2 mb-2 justify-between items-center'>
                <Table size={'small'}
                       className='w-full'
                    // onChange={(pagination, filters, sorter: any) => {
                    //     console.log(sorter);
                    //     if (sorter.field === "name") {
                    //         if (sorter.order === "ascend") {
                    //             setNameBy('ascend');
                    //         } else if (sorter.order === "descend") {
                    //             setNameBy('descend');
                    //         } else {
                    //             setNameBy(null);
                    //         }
                    //     }
                    // }}
                       dataSource={list} pagination={false} columns={columns}/>
                <div className='flex w-full items-center justify-end' style={{height: '70px'}}>
                    <Pagination current={pageIndex}
                                total={total}
                                defaultCurrent={1}
                                pageSize={pageSize}
                                pageSizeOptions={['10', '20', '30', total.toString()]}
                                size={'small'}
                                showLessItems={true}
                                onShowSizeChange={(current, size) => {
                                    setPageIndex(current)
                                    setPageSize(size)
                                }}
                                onChange={(current, size) => {
                                    setPageIndex(current)
                                    setPageSize(size)
                                }}/>
                </div>
            </div>
        </div>
    )
}


export default Users;
