import {GET} from "../request";


class BankTradeApi {
    static getList({queryKey}:any){

        const data = queryKey[1];

        return GET(`/v1/BankTrade/list/`, {...data,psnl:1});
    }
}

export default BankTradeApi;