import React, {useState} from "react";
import { CaretRightOutlined,CaretDownOutlined} from '@ant-design/icons';

const Profit = () => {

  const [openList,setOpenList] = useState([true,true]);


  const setOpen = (index:number)=>{
    const _list = [...openList];
    _list[index] = !_list[index];

    console.log(_list);
    setOpenList(_list);
  }

  return (
    <div className='w-full h-full'>
      <div className='w-600 mr-auto ml-auto mt-6 border border-solid'>
        <div className='text-center border-b border-r-0 border-l-0 border-t-0 border-solid'>ABC Inc</div>
        <div className='text-center border-b border-r-0 border-l-0 border-t-0 border-solid'>Profit and Loss</div>
        <div className='text-center border-b border-r-0 border-l-0 border-t-0 border-solid'>January - December 2022
        </div>
        <div
          className='w-full flex h-28 items-center justify-between border-b border-r-0 border-l-0 border-t-0 border-solid'>
          <div/>
          <div className='border-0 leading-28 text-right pr-1 border-r-0 border-l border-t-0 border-solid h-full w-100'>Total</div>
        </div>

        <div className={`w-full`}>
          <div className={`flex items-center h-28 justify-between border-b border-r-0 border-l-0 border-t-0 border-solid`}>
            <div className='flex-1 flex items-center' onClick={()=>setOpen(0)}>
              {openList[0] ? <CaretDownOutlined /> : <CaretRightOutlined />}
              <div className='ml-1'>Income</div>
            </div>
            <div className='border-0 border-r-0 border-l border-t-0 border-solid h-full w-100'></div>
          </div>
          <div className='w-full overflow-hidden' style={{height: openList[0] ? 'auto' : '0px'}}>
            {
              [1,2,4].map((item)=>{



                return (
                  <div key={item} className='w-full flex items-center justify-betweens pl-3 border-b border-r-0 border-l-0 border-t-0 border-solid'>
                    <div className='flex-1'>Income</div>
                    <div className='leading-28 pr-1 text-right border-0 border-r-0 border-l border-t-0 border-solid h-28 w-100'>123</div>
                  </div>
                )
              })
            }
          </div>
        </div>

        <div
          className='w-full flex h-28 items-center justify-between border-b border-r-0 border-l-0 border-t-0 border-solid'>
          <div>Total Income</div>
          <div className='border-0 pl-1 leading-28 flex items-center justify-between pr-1 border-r-0 border-l border-t-0 border-solid h-full w-100'>
            <div>$</div>
            <div>123</div>
          </div>
        </div>

        <div
          className='w-full flex h-28 items-center justify-between border-b border-r-0 border-l-0 border-t-0 border-solid'>
          <div>Gross Profit</div>
          <div className='border-0 pl-1 leading-28 flex items-center justify-between pr-1 border-r-0 border-l border-t-0 border-solid h-full w-100'>
            <div>$</div>
            <div>123</div>
          </div>
        </div>

        <div className={`w-full`}>
          <div className={`flex items-center h-28 justify-between border-b border-r-0 border-l-0 border-t-0 border-solid`}>
            <div className='flex-1 flex items-center' onClick={()=>setOpen(1)}>
              {openList[0] ? <CaretDownOutlined /> : <CaretRightOutlined />}
              <div className='ml-1'>Expenses</div>
            </div>
            <div className='border-0 border-r-0 border-l border-t-0 border-solid h-full w-100'></div>
          </div>
          <div className='w-full overflow-hidden' style={{height: openList[1] ? 'auto' : '0px'}}>
            {
              [1,2,4].map((item)=>{
                return (
                  <div key={item} className='w-full flex items-center justify-betweens pl-2 border-b border-r-0 border-l-0 border-t-0 border-solid'>
                    <div className='flex-1'>Income</div>
                    <div className='leading-28 pr-1 text-right border-0 border-r-0 border-l border-t-0 border-solid h-28 w-100'>123</div>
                  </div>
                )
              })
            }
          </div>
        </div>

        <div
          className='w-full flex h-28 items-center justify-between border-b border-r-0 border-l-0 border-t-0 border-solid'>
          <div>Total Expenses</div>
          <div className='border-0 pl-1 leading-28 flex items-center justify-between pr-1 border-r-0 border-l border-t-0 border-solid h-full w-100'>
            <div>$</div>
            <div>123</div>
          </div>
        </div>

        <div
          className='w-full flex h-28 items-center justify-between border-b border-r-0 border-l-0 border-t-0 border-solid'>
          <div>Net Operating Income</div>
          <div className='border-0 pl-1 leading-28 flex items-center justify-between pr-1 border-r-0 border-l border-t-0 border-solid h-full w-100'>
            <div>$</div>
            <div>123</div>
          </div>
        </div>

        <div
          className='w-full flex h-28 items-center justify-between'>
          <div>Net Income</div>
          <div className='border-0 pl-1 leading-28 flex items-center justify-between pr-1 border-r-0 border-l border-t-0 border-solid h-full w-100'>
            <div>$</div>
            <div>123</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profit;
