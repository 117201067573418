import React, {useEffect, useRef, useState} from "react";
import {useMutation, useQuery} from "@tanstack/react-query";
import PlaidApi from "../../common/api/PlaidApi";
import {Button, message, Pagination, Tabs,} from "antd";
// @ts-ignore
import FileSaver from "file-saver";
// @ts-ignore
import {Scrollbars} from 'react-custom-scrollbars';
import {useNavigate} from "react-router-dom";
import {AppRoutePath} from "../../routers";
import PlaidClassList from "../../pages/uploadCSV/List";
import {tab} from "@testing-library/user-event/dist/tab";
import {getFileName} from "../../common/utils";


interface IPlaidListProps {
    date?: { startDate: '', endData: '' };
    tabs: any[];
}

const PlaidList = (props: IPlaidListProps) => {

    const {date, tabs} = props;

    const navigate = useNavigate();

    const [messageApi, contextHolder] = message.useMessage();
    const [list, setList] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(100);

    const [params, setParams] = useState(date);

    const [total, setTotal] = useState(0);
    const [accountId, setAccountId] = useState('');
    const [tabList, setTabList] = useState<any>([]);

    useEffect(() => {
        if (tabs.length > 0) {
            setAccountId(tabs[0]?.account_id)

            const data = {list: tabs, date: date};

            window.sessionStorage.setItem('accounts', JSON.stringify(data));
        }
    }, [tabs]);


    useQuery(['getPlaidList', {
        page: pageIndex,
        page_size: pageSize,
        // record: 0,
        account_id: accountId,
        day__lte: params?.endData || null,
        day__gte: params?.startDate || null
    }], PlaidApi.getList, {
        enabled: !!accountId,
        onSuccess: (result) => {
            if (result.data) {
                setList(result.data.data);
                setTotal(result.data.limit);
            }
        }
    })

    const importApi = useMutation(PlaidApi.importFile, {
        onSuccess: (result) => {

            const disposition = result.headers['content-disposition'];
            const fileName: any = getFileName(disposition);

            let url = window.URL.createObjectURL(result.data);

            console.log(url);

            let link = document.createElement("a");

            link.style.display = "none";

            link.href = url;

            link.setAttribute("download", fileName);//文件名后缀记得添加，我写的zip

            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);//下载完成移除元素

            window.URL.revokeObjectURL(url);//释放掉blob对象
        }
    })

    const submit = useMutation(PlaidApi.save, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                navigate(AppRoutePath.transactions);
            } else {
                messageApi.error({content: result.data.msg});
            }
        }
    })

    const agree = () => {

        console.log(params);

        if (params?.startDate || params?.endData) {
            submit.mutate({day__gte: params.startDate, day__lte: params.endData});
        } else {
            submit.mutate(null);
        }
        window.sessionStorage.removeItem('accounts');
    }

    const importExec = () => {

        if (params?.startDate || params?.endData) {
            importApi.mutate({day__gte: params.startDate, day__lte: params.endData})

        } else {
            importApi.mutate(null);
        }


    }


    if (!tabs) {
        return null;
    }


    return (
        <div className='p-2 flex flex-col m-2 border box-border border-gray-200 border-solid flex-1 overflow-hidden'>
            {contextHolder}
            <div className='flex-1 w-full h-full'>
                <Tabs
                    className='flex-1 w-full plaid-style h-full'
                    defaultActiveKey="1"
                    items={tabs?.map((_, i) => {
                        const id = String(i + 1);
                        return {
                            label: `${_.name} **** ${_.mask}`,
                            key: _.account_id,
                            className: 'w-full h-full',
                            children: <div className='flex w-full h-full flex-col'>
                                <div className='flex-1 w-full h-full'>
                                    <PlaidClassList data={list}/>
                                </div>
                                <div className='w-full flex items-center justify-end h-40'>
                                    <Pagination current={pageIndex}
                                                total={total}
                                                pageSize={pageSize}
                                                pageSizeOptions={['10', '20', '50', total.toString()]}
                                                size={'small'}
                                                showLessItems={true}
                                                onShowSizeChange={(current, size) => {
                                                    setPageIndex(current);
                                                    setPageSize(isNaN(size) ? total : size);
                                                }}
                                                onChange={(current) => {
                                                    setPageIndex(current);
                                                }}/>
                                </div>
                            </div>,
                        };
                    })}
                    onChange={(e) => {
                        setAccountId(e);
                        setPageIndex(1);
                        setPageSize(20);
                    }}
                />
            </div>


            <div className='flex mt-1 items-center justify-between'>
                <div>
                    <Button danger type={'primary'} onClick={()=>{
                        window.sessionStorage.removeItem('accounts');
                        window.location.reload();
                    }}>empty</Button>
                </div>
                {
                    tabs.length > 0 && <div>
                        <Button type={'primary'} onClick={agree}>agree</Button>
                        <Button type={'primary'} className='ml-2' onClick={importExec}>Export</Button>
                    </div>
                }

            </div>
        </div>
    )
}

export default PlaidList
