import React, {useState} from "react";
import {Button, Input, Form, message} from "antd";
import {RequiredMark} from "antd/es/form/Form";
// @ts-ignore
import LoginBg from "../../assets/images/home_login_bg.svg";
import User from "../../common/api/user";
import {useMutation} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {AppRoutePath} from "../../routers";
import {removerSpace} from "../../common/utils";
import {initRequestToken} from "../../common/request";

const Login = ()=>{

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [requiredMark, setRequiredMarkType] = useState<RequiredMark>('optional');

    const loginApi = useMutation(User.login,{
      onSuccess:(result)=>{
        if (result.data.status === "success"){
          messageApi.success(result.data.msg);
          initRequestToken(result.data.token);
          localStorage.setItem('userInfo',JSON.stringify(result.data.data));
          setTimeout(()=>{
            navigate(AppRoutePath.transactions);
          },1000)

        }else{
          messageApi.error(result.data.msg);
        }
      }
    })

    const onSubmit = async (data:any) => {

      const email = removerSpace(data.email);
      const password = removerSpace(data.password);

      if (email.length > 0){
        if (password.length > 0){
          loginApi.mutate({email:email,password:password});
        }else{

        }
      }else{

      }
    };

    return (
        <div className='w-full h-full bg-white'>
            {contextHolder}
            <div className='flex w-full h-full items-center justify-center pr-4'>
                <div className='mr-20v'>
                    <img width={300} src={LoginBg}/>
                </div>
                <div className='bg-white shadow rounded p-3 w-300 pt-2 pb-4'>
                    <div className='text-22 pt-2 pb-2'>Login your Account</div>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={onSubmit}
                        autoComplete="off"
                    >
                        <Form.Item label="email" className='login-input' rules={[{ required: true, message: 'Please input your email!' }]} name="email">
                           <Input placeholder=''/>
                        </Form.Item>

                        <Form.Item label="password" className='login-input' rules={[{ required: true, message: 'Please input your password!' }]} name="password">
                            <Input.Password/>
                        </Form.Item>
                        <Form.Item className='mt-5' labelCol={{span:2}}>
                            <Button loading={loginApi.isLoading} type="primary" className='max-w-full w-full' style={{width:'100%'}} htmlType="submit">Submit</Button>
                        </Form.Item>
                        <div className='flex items-center justify-center'>
                          <Button onClick={()=>{
                            navigate(AppRoutePath.register);
                          }} type={"link"}>Sign up</Button>
                        </div>
                    </Form>

                </div>
            </div>
        </div>
    )
}

export default Login
