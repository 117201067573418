import {GET, POST} from "../request";

interface IRuleCreateParams{
  id?:string;
  kind_id:string;
  content:string[];
  user_id?:number;
}

class RuleApi{
  static create(data:IRuleCreateParams){
    return POST('/v2/Rule/operate/',data,true);
  }

  static update(data:IRuleCreateParams){
    return POST('/v2/Rule/operate/',data,true);
  }

  static getList({queryKey}:any){
    const data = queryKey[1];
    console.log(data);
    return GET('/v2/Rule/list/',{page:data.page,user_id:data.user_id,page_size:20,user_id__gt:data.user_id__gt,is_global:data.is_global},true);
  }

  static remove(id:string){
    return POST(`/v1/Rule/delete/${id}`,null,true);
  }
}

export default RuleApi;
