import React from "react";
import BqLayout from "../../components/layout";

import {Outlet} from "react-router-dom";


const Home = () => {

  return (
    <BqLayout>
      <Outlet></Outlet>
    </BqLayout>
  )
}

export default Home;
