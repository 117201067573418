import { GET, POST } from '../request';

export enum IGroup {
    'Assets ' = 1,
    'Liability',
    'Equity',
    'Income',
    'Cost of Goods Sold',
    'Employee Expense',
    'General Business Expense',
    'Marketing and Advertising',
    'Other Expenses',
}

interface IKindCreateParams {
    id?: string;
    name: string;
    group: number;
    sort: string;
}

class KindApi {
    static getList({ queryKey }: any) {
        const data = queryKey[ 1 ];
        return GET('/v2/Kind/list/', { ...data, page_size: 99999 });
    }
    
    static getPageList({ queryKey }: any) {
        const data = queryKey[ 1 ];
        
        if (data.nameby) {
            return GET('/v2/Kind/list/', {
                page: data.page,
                psnl: 1,
                page_size: data.page_size,
                order_by: `${ data.nameby === 'descend' ? '-' : '' }number`
            });
        } else {
            return GET('/v2/Kind/list/', { page_size: data.page_size, psnl: 1, page: data.page });
        }
        
    }
    
    static create(data: IKindCreateParams) {
        return POST('/v1/Kind/operate/', data, true);
    }
    
    static remove(id: string) {
        return POST(`/v1/Kind/delete/${ id }`, null, true);
    }
    
    static getKindList(num: string | number) {
        return POST(`v1/kind/list/?number__startswith=${ num }`);
    }
    
    static askSingle(data: { trade_id: string; user_desc: string }) {
        return POST(`/v1/ask_single`, data);
    }
}


export default KindApi;
