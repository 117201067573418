import React from "react";
import {Button} from "antd";
import {EnterData} from "../modal";


const JournalEntry = ()=>{
    return (
        <div className='w-full h-full bg-white relative setting-view'>
            <div className='w-full flex items-center pl-2 pt-1 pb-2 bg-white'>
                <div className='text-22'>New Journal Entry</div>
            </div>
            <div className='flex pl-2 pr-2 mt-2 mb-2 justify-between items-center'>
                <EnterData/>
            </div>
        </div>
    )
}

export default JournalEntry;