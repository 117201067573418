import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {
    usePlaidLink,
    PlaidLink,
    PlaidLinkOptions,
    PlaidLinkOnSuccess, PlaidLinkOnSuccessMetadata,
} from 'react-plaid-link';
import {Button, message, Modal, Select} from "antd";
import {useMutation} from "@tanstack/react-query";
import PlaidApi from "./api/PlaidApi";
import {LoadingOutlined} from "@ant-design/icons";


// const Plaid = ({
//                    change,
//                    plaidUser,
//                    showUpDate,
//                    plaidId
//                }: { change: (status: boolean) => void; plaidUser: any[], showUpDate: boolean, plaidId: string | number }) => {

const Plaid = forwardRef(({
                              token,
                              onChange,
                              selectUser,
                              type,
                              change
                          }: { token: string; onChange: () => {}, type: string, selectUser: string | null, change: any }, ref: any) => {


    const [messageApi, contextHolder] = message.useMessage();

    const data: PlaidLinkOptions = {
        onSuccess: (public_token, metadata) => {
            console.log(public_token);
            // setStatus(false);
            //
            set.mutate({public_token: public_token, auth_info: metadata, id: selectUser || null});
            // window.sessionStorage.setItem('auth', public_token);
        },
        clientName: 'Technology Credit',
        onExit: (err, metadata) => {
            onChange();
        },
        onEvent: (eventName, metadata) => {
            console.log(metadata);
        },
        onLoad: () => {
            console.log('init plaid');
            console.log(token);
        },
        token: token,
    };

    const {open, exit, ready, error} = usePlaidLink(data);

    const set = useMutation(PlaidApi.setPublicToken, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                message.success('Thank you for connecting your bank accounts. You are all set!');
                synchronousApi.mutate();
            } else {
                messageApi.error(result.data.msg);
            }
            onChange();
        }
    })

    const synchronousApi = useMutation(PlaidApi.transactionSync, {
        onSuccess: (result) => {
            change(result.data.status === 'success' && type);

            if (result.data.status === 'success') {
                messageApi.success(result.data.msg);
            } else {
                messageApi.error(result.data.msg);
            }
        }
    })

    useEffect(() => {
        console.log(ready);
        if (ready) {
            open();
        }
    }, [ready])

    useEffect(() => {
        if (!token) {
            exit();
        }
    }, [token])


    return (
        <div>
            {contextHolder}
        </div>
    )
});

const plaidHook = (Component: any) => {
    return function WrappedComponent(props: any) {

        const {showUpDate, plaidUser, plaidId, change} = props;

        const [type, setType] = useState('success');

        const plaidDom: any = useRef();
        const [messageApi, contextHolder] = message.useMessage();

        const [token, setToken] = useState('');
        const [user, setUser] = useState<any>(null);
        const [isPlaidUserOpen, setIsPlaidUserOpen] = useState<boolean>(false);
        const [selectUser, setSelectUser] = useState('');

        useEffect(() => {

            console.log(props);

            const userInfo = window.localStorage.getItem('userInfo');

            if (userInfo) {
                const _userInfo = JSON.parse(userInfo);

                setUser(_userInfo);
            }
        }, [])


        const getLinkToken = useMutation(PlaidApi.getLinkToken, {
            onSuccess: (result) => {
                if (result.status) {
                    setToken(result.data.data.link_token);

                }
            }
        })

        const synchronousApi = useMutation(PlaidApi.transactionSync, {
            onSuccess: (result) => {
                change(result.data.status === 'success' && type);

                if (result.data.status === 'success') {
                    messageApi.success(result.data.msg);
                } else {
                    messageApi.error(result.data.msg);
                }
            }
        })

        const set = useMutation(PlaidApi.setPublicToken, {
            onSuccess: (result) => {
                if (result.data.status === 'success') {
                    message.success('Thank you for connecting your bank accounts. You are all set!');
                    synchronousApi.mutate();
                } else {
                    messageApi.error(result.data.msg);
                }
            }
        })

        const update = useMutation(PlaidApi.updatePlaidAccount, {
            onSuccess: (result) => {
                if (result.data.status) {
                    setToken(result.data.data.link_token);
                }
            }
        })

        const synchronous = () => {
            setType('success');
            synchronousApi.mutate();
            // agreeStatus.mutate();
        }

        const openPlaid = () => {
            setType('success');
            getLinkToken.mutate({});
        }

        const updatePlaid = () => {

            if (plaidId) {
                setType('refresh');
                update.mutate(plaidId);
            } else {
                messageApi.warning('Account ID needs to be passed in!');
            }
        }

        const selectPlaidUser = () => {
            if (selectUser) {
                getLinkToken.mutate({id: selectUser});
            } else {
                message.warning('Please select Plaid User!');
            }
        }


        return (
            <div className='flex items-center'>
                {contextHolder}

                {showUpDate &&
                    <div className='flex items-center'>
                        <Button onClick={updatePlaid} type={'primary'}
                                className='mr-2'>Update</Button>
                    </div>}


                {
                    !user?.can_switch_out && (
                        <Button type={'primary'} className='mr-2'
                                disabled={getLinkToken.isLoading || set.isLoading || (user?.role !== 1 && synchronousApi.isLoading)}
                                onClick={() => openPlaid()}>
                            <div className='flex items-center'>
                                <div>{(getLinkToken.isLoading || set.isLoading || (user?.role !== 1 && synchronousApi.isLoading)) &&
                                    <LoadingOutlined className='mr-1' color={'#FFF'} style={{fontSize: 14}} spin/>}</div>
                                Link Plaid
                            </div>
                        </Button>
                    )
                }

                {
                    (user?.role === 1 || user?.can_switch_out) && (
                        <Button className='mr-2' type={'primary'} disabled={synchronousApi.isLoading} onClick={synchronous}>
                            <div className='flex items-center'>
                                <div>{synchronousApi.isLoading &&
                                    <LoadingOutlined className='mr-1' color={'#FFF'} style={{fontSize: 14}} spin/>}</div>
                                Sync Plaid
                            </div>
                        </Button>
                    )
                }


                {
                    token && <Component ref={plaidDom} type={type} change={change} {...props} token={token}
                                        selectUser={selectUser}
                                        onChange={() => {
                                            setToken('')
                                        }}/>
                }


                {/*<Modal open={isPlaidUserOpen} footer={null} onCancel={() => {*/}
                {/*    setIsPlaidUserOpen(false)*/}
                {/*    setSelectUser('');*/}
                {/*}} title='Select Plaid User' destroyOnClose={true}>*/}
                {/*    <div className=''>*/}
                {/*        <Select className='w-full mb-3'*/}
                {/*                placeholder='plaid user'*/}
                {/*                onChange={(value, option) => {*/}
                {/*                    setSelectUser(value);*/}
                {/*                }}*/}
                {/*                options={plaidUser.map((item: any) => {*/}
                {/*                    return {value: item.id, label: item.auth_info.account.name};*/}
                {/*                })}/>*/}
                {/*        <div className='flex items-center justify-end'>*/}
                {/*            <Button className='mr-2' type={'primary'} onClick={() => {*/}
                {/*                getLinkToken.mutate({});*/}
                {/*            }}>Create New Plaid User</Button>*/}
                {/*            <Button onClick={selectPlaidUser}*/}
                {/*                    disabled={getLinkToken.isLoading || set.isLoading || (user?.role !== 1 && synchronousApi.isLoading)}>*/}
                {/*                <div*/}
                {/*                    className='flex items-center'>{(getLinkToken.isLoading || set.isLoading || (user?.role !== 1 && synchronousApi.isLoading)) &&*/}
                {/*                    <LoadingOutlined className='mr-1' color={'#FFF'} style={{fontSize: 14}}*/}
                {/*                                     spin/>} Select Plaid User*/}
                {/*                </div>*/}

                {/*            </Button>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Modal>*/}

            </div>
        );
    }
}


export default plaidHook(Plaid);
