import React, {useEffect, useState} from "react";
import {Button, Card, DatePicker, message, Modal, notification, Pagination, Radio, Select, Table} from 'antd';
import {useNavigate} from "react-router-dom";
// @ts-ignore
import {Scrollbars} from 'react-custom-scrollbars';
import {useMutation, useQuery, useQueryClient, UseQueryOptions} from "@tanstack/react-query";
import Plaid from "../../common/Plaid";
import dayjs from "dayjs";
import PlaidList from "../../components/PlaidList";
import PlaidApi from "../../common/api/PlaidApi";
import PlaidClassList from "./List";
import {AppRoutePath} from "../../routers";
import IconFont from "../../components/iconfont";

const {RangePicker} = DatePicker;

const SyncPlaid = () => {

    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const [messageApi, contextHolder] = message.useMessage();

    const [isPlaidOpen, setIsPlaidOpen] = useState(false);
    const [isPlaidUserOpen, setIsPlaidUserOpen] = useState(false);
    const [plaidListType, setPlaidListType] = useState(2);
    const [account, setAccount] = useState([]);
    const [farmAccount,setFarmAccount] = useState([]);

    const [plaidDate, setPlaidDate] = useState<{ startDate: '', endData: '' }>({startDate: '', endData: ''})
    const [plaidDateError, setPlaidDateError] = useState('');
    const [isPlaidResult, setIsPlaidResult] = useState(false);
    const [user, setUser] = useState<any>(null);
    const [plaidUse, setPlaidUser] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [accoutId, setAccoutId] = useState('');
    const [showUpDate, setShowUpDate] = useState(false);
    const [plaidId, setPlaidId] = useState('');
    const [openAccounts, setOpenAccounts] = useState(false);
    const [plaidUsers, setPlaidUsers] = useState([]);

    useEffect(() => {

        const userInfo = window.localStorage.getItem('userInfo');

        if (userInfo) {
            const _userInfo = JSON.parse(userInfo);


            setUser(_userInfo);
        }

    }, [])

    useEffect(() => {
        if (accoutId) {
            queryRecord(accoutId);
        }
    }, [pageSize, pageIndex, accoutId]);

    useEffect(() => {
        const _accountData = window.sessionStorage.getItem('accounts');
        if (_accountData) {
            const _accountList = JSON.parse(_accountData);

            setIsPlaidResult(true);
            setAccount(_accountList.list);

            if (_accountList.date) {
                setPlaidDate(_accountList.date);
            }

        }
    }, [])


    useEffect(()=>{
        refreshAccount();
    },[account])


    const refreshAccount = ()=>{
        if (account.length > 0){
            setFarmAccount([...account]);
        }
    }

    const columns = [
        {
            title: 'name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'subtype',
            dataIndex: 'subtype',
            key: 'subType',
        },
        {
            title: 'action',
            key: 'action',
            render: (v: any, d: any) => {
                return (
                    <div>
                        {/*{*/}
                        {/*    d.*/}
                        {/*}*/}
                        {
                            !d.enabled && (
                                <Button type='link' onClick={() => {
                                    setPlaidAccountsStatus(true, d.id)
                                }
                                }>Enable
                                </Button>
                            )
                        }
                        {
                            d.enabled && (
                                <Button type='link' danger onClick={() => {
                                    setPlaidAccountsStatus(false, d.id)
                                }
                                }>Disable
                                </Button>
                            )
                        }


                    </div>
                )
            }
        },
    ];

    useQuery({
        queryKey: ['getPlaidUsers'],
        queryFn: PlaidApi.getPlaidUsers,
        onSuccess: (result) => {
            if (result.data.limit > 0) {

                PlaidApi.getFilterPlaidUsers().then((filterResult)=>{
                    // setPlaidUser();


                    console.log(result.data.data);

                    let resultList:any = [];

                    result.data.data.forEach((_item:any)=>{
                        let arr:any = [];


                        if (_item.auth_info.accounts?.length > 0){
                            _item.auth_info.accounts.forEach((_account:any)=>{
                                filterResult.data.data.forEach((item:any)=>{
                                    if ((_account.account_id || _account.id) === item.account_id){
                                        arr.push(_account);
                                    }
                                })
                            })
                        }




                        if (arr.length > 0){
                            resultList.push({..._item,auth_info: {..._item.auth_info,accounts:arr}})
                        }
                    })


                    console.log(resultList);

                    setPlaidUser(resultList);
                })


            }
        }
    })

    const setiiApi = useMutation(PlaidApi.setii,{

    });

    const {isLoading} = useQuery({
        queryKey: ['setAccount'],
        queryFn: PlaidApi.queryAccount,
        enabled: account?.length === 0,
        onSuccess: (result) => {
            if (result.data.error_code !== 'ITEM_LOGIN_REQUIRED') {
                setAccount(result.data.accounts);
            }
        }
    })

    const enablePlaidApi = useMutation(PlaidApi.enablePlaidStatus, {
        onSuccess: (result) => {
            if (result.data.status === 'success'){
                setOpenAccounts(false);
                queryClient.refetchQueries(['getPlaidUsers']);
                refreshAccount();
                if(result.data.msg){
                    messageApi.success(result.data.msg);
                }else{
                    messageApi.success('success');
                }

            }else{
                messageApi.error(result.data.msg);
            }
        }
    })


    const queryRecord = (id: string) => {
        PlaidApi.getList({
            queryKey: ['getPlaidList', {
                "account_id": id,
                "day__gte": null,
                "day__lte": null,
                "page": pageIndex,
                "record": 0,
                "page_size": pageSize,
            }]
        }).then((result) => {
            if (result.data) {
                setList(result.data.data);
                setTotal(result.data.limit);
            }
        })
    }

    const setPlaidAccountsStatus = (status: boolean, id: string) => {
        enablePlaidApi.mutate({enabled: status, id: id});
    }

    const asyncQueryAccount = useMutation(PlaidApi.queryAccount, {
        onSuccess: (result) => {
            if (result.data.error_code !== 'ITEM_LOGIN_REQUIRED') {
                setAccount(result.data.accounts);
                getPlaidAccount();
            } else {
                message.error(result.data.msg);
            }
        }
    })


    const viewPlaid = (e: any) => {
        if (plaidListType === 1) {

            if (!plaidDate.startDate) {
                return setPlaidDateError('Please select a date')
            }

            console.log(plaidDate);

        } else {
            setPlaidDate({startDate: '', endData: ''})
        }

        setIsPlaidResult(true);
        setIsPlaidOpen(false);
    }

    const getPlaidAccount = async () => {

        if ((user?.role === 1 || user?.can_switch_out)) {
            // const result = await PlaidApi.queryAgreeStatus();
            // if (result.data.data.exists) {
            //
            // } else {
            //     message.success('Thank you for connecting your bank accounts. You are all set!');
            // }

            setIsPlaidOpen(true);
            setIsPlaidResult(false);
        } else {
            message.success('Thank you for connecting your bank accounts. You are all set!');
            setIsPlaidOpen(false);
            setIsPlaidResult(false);
        }
    }

    const getAccoutAsync = () => {
        setIsPlaidResult(false);
        asyncQueryAccount.mutate();
        queryClient.refetchQueries(['getPlaidUsers']);
    }

    const getRecord = (id: string) => {
        setAccoutId(id);
        queryRecord(id);
    }

    const submit = useMutation(PlaidApi.save, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                navigate(AppRoutePath.transactions);
            } else {
                messageApi.error({content: result.data.msg});
            }
        }
    })

    const agree = () => {

        submit.mutate({account_id: accoutId});

        window.sessionStorage.removeItem('accounts');
    }

    const empty = () => {

    }

    const updatePlaid = () => {

    }


    const queryPlaidAccounts = useMutation(PlaidApi.plaidAccounts, {
        onSuccess: (result) => {
            if (result.data.limit > 0) {
                setPlaidUsers(result.data.data);
                setOpenAccounts(true)
            } else {
                message.warning('No play account found！');
            }

        }
    })


    return (
        <div className='w-full h-full flex flex-col bg-white'>
            {contextHolder}

            <div className='w-full flex items-center justify-between pl-2 pt-1 pb-2 bg-white'>
                <div className='text-22'>Plaid</div>

                <div className='flex items-center'>
                    {/*<Button onClick={()=>setiiApi.mutate()}>123123</Button>*/}
                    {
                        !isLoading && (user?.role === 1 || user?.can_switch_out) && (
                            <div className='mr-1'>
                                <Button onClick={() => {
                                    queryPlaidAccounts.mutate()
                                }} type={'primary'}>Plaid Accounts</Button>
                            </div>
                        )
                    }

                    {
                        !isLoading && (
                            <Plaid plaidId={plaidId} showUpDate={showUpDate} plaidUser={plaidUse} change={(status: any) => {
                                if (status === 'success') {
                                    getAccoutAsync();
                                }else if(status === 'refresh'){
                                    setShowUpDate(false);
                                    queryClient.refetchQueries(['getPlaidUsers']);
                                }
                            }}/>
                        )
                    }

                </div>

            </div>


            <div style={{flex: 1, display: 'flex', flexDirection: 'column', padding: '20px 0'}}>
                {
                    isPlaidResult && (<PlaidList tabs={farmAccount} date={plaidDate}/>)
                }

                {
                    !isPlaidResult && (
                        <div className='w-full h-full flex flex-col'>
                            <div className='w-full flex pr-2 pl-2 pb-2 items-center'
                                 style={{overflowX: 'scroll'}}>
                                <div className='flex items-center'>
                                    {
                                        plaidUse.map((item: any,index:number) => {
                                            if (item.auth_info.accounts.length > 0) {
                                                return (
                                                    <div key={index}>
                                                        {/*<div className='mb-1 font-bold text-l'>*/}
                                                        {/*    {item.auth_info.account?.name} {item.auth_info.account?.mask}*/}
                                                        {/*</div>*/}
                                                        <div className='flex items-center'>
                                                            {
                                                                item.auth_info.accounts.map((_item: any) => {
                                                                    return (
                                                                        <Card onClick={() => {

                                                                            if (item.need_update) {
                                                                                setList([]);
                                                                                setTotal(0);
                                                                                setAccoutId(_item.id || _item.account_id);
                                                                                setPlaidId(item.id);
                                                                                setShowUpDate(true);
                                                                            } else {
                                                                                getRecord(_item.id || _item.account_id);
                                                                                setShowUpDate(false);
                                                                            }

                                                                        }} size={"small"}
                                                                              key={_item.account_id}
                                                                              className={`mr-1 cursor-pointer w-200 ${accoutId === (_item.id || _item.account_id) ? 'bg-primary border-primary text-white' : ''}`}>
                                                                            <div className='flex items-center '>
                                                                                <div className='flex-1 truncate'>
                                                                                    {_item.name}
                                                                                </div>
                                                                                {
                                                                                    item.need_update && (
                                                                                        <div className=''>
                                                                                            <IconFont
                                                                                                color={(_item.id || _item.account_id) === accoutId ? ['#FFF', '#FFF'] : []}
                                                                                                name={'jinggao'}/>
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                            </div>
                                                                            <div>**** **** **** {_item.mask}</div>
                                                                        </Card>
                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                )

                                            }
                                        })
                                    }
                                </div>
                            </div>

                            {/*{*/}
                            {/*    (user?.can_switch_out) && (*/}
                            {/*        <div className='pr-2 flex-1 flex flex-col pl-2'>*/}
                            {/*            <div className='w-full' style={{height: '480px'}}>*/}
                            {/*                <PlaidClassList data={list}/>*/}
                            {/*            </div>*/}
                            {/*            {*/}
                            {/*                total > 0 && (*/}
                            {/*                    <div className='w-full mt-3 flex justify-between items-center justify-end'>*/}
                            {/*                        <div>*/}
                            {/*                            <Button onClick={agree} type='primary'>Agree</Button>*/}
                            {/*                            /!*<Button onClick={empty} type='primary' danger className='ml-1'>Empty</Button>*!/*/}
                            
                            {/*                        </div>*/}
                            
                            {/*                        <Pagination current={pageIndex}*/}
                            {/*                                    total={total}*/}
                            {/*                                    pageSize={pageSize}*/}
                            {/*                                    pageSizeOptions={['10', '20', '50', total.toString()]}*/}
                            {/*                                    size={'small'}*/}
                            {/*                                    showLessItems={true}*/}
                            {/*                                    onShowSizeChange={(current, size) => {*/}
                            {/*                                        setPageIndex(current);*/}
                            {/*                                        setPageSize(isNaN(size) ? total : size);*/}
                            {/*                                    }}*/}
                            {/*                                    onChange={(current) => {*/}
                            {/*                                        setPageIndex(current);*/}
                            {/*                                    }}/>*/}
                            {/*                    </div>*/}
                            {/*                )*/}
                            {/*            }*/}
                            
                            {/*        </div>*/}
                            {/*    )*/}
                            {/*}*/}


                        </div>
                    )
                }

            </div>


            <Modal open={isPlaidOpen} footer={null} onCancel={() => {
                setIsPlaidOpen(false)
            }} title='View Plaid Data'>
                <div className='w-full'>
                    <div className='flex w-full flex-col'>
                        <Radio checked={plaidListType === 1} onClick={() => setPlaidListType(1)}>
                            <div className='relative w-full'>
                                <div>Date</div>
                                <div className='absolute w-500' style={{bottom: -60, left: 0}}>

                                    <RangePicker
                                        status={plaidDateError ? 'error' : ''}
                                        onChange={(e: any) => {
                                            const _startDate: any = dayjs(e[0]).format("YYYY-MM-DD") || '';
                                            const _endData: any = dayjs(e[1]).format("YYYY-MM-DD") || '';

                                            setPlaidDateError('');
                                            setPlaidDate({
                                                startDate: _startDate,
                                                endData: _endData
                                            });
                                        }}/>
                                    <div className='text-error h-20'>{plaidDateError}</div>
                                </div>
                            </div>
                        </Radio>
                        <div className='mt-6'>
                            <Radio checked={plaidListType === 2} onClick={() => setPlaidListType(2)}>View All</Radio>
                        </div>
                    </div>
                    <div className='flex items-center justify-end'>
                        <Button onClick={() => setIsPlaidOpen(false)}>cancel</Button>
                        <Button type={'primary'} className='ml-2' onClick={viewPlaid}>ok</Button>
                    </div>
                </div>
            </Modal>

            <Modal title='accounts' onCancel={() => {
                setOpenAccounts(false);
                setPlaidUsers([]);
            }} footer={null} open={openAccounts}>
                <div className='w-full mt-2 h-500'>
                    {
                        //@ts-ignore
                        <Table pagination={false} dataSource={plaidUsers} virtual scroll={{x: 472, y: 420}}
                               className='h-full' columns={columns}/>
                    }
                </div>
            </Modal>

        </div>
    )
}

export default SyncPlaid;
