import React, {useState} from "react";
import {Button, DatePicker, Input, message, Modal, Popconfirm, Select, Table} from "antd";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import user from "../../common/api/user";
import SettingApi from "../../common/api/setting";
import PlaidApi from "../../common/api/PlaidApi";
import dayjs from "dayjs";
import KindApi from "../../common/api/kind";

interface IListItem {
    create_time: string;
    id: number;
    name: "debit" | "credit"
    type: number;
    type_info: number;
    update_time: string;
    mask: string;
    user: number;
    val: string;
}

const SetBalance = () => {


    const queryClient = useQueryClient();

    const [accountBalance, setAccountBalance] = useState('');
    const [name, setName] = useState<'debit' | 'credit'>('debit');
    const [messageApi, contextHolder] = message.useMessage();
    const [list, setList] = useState<IListItem[]>([]);
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(9999);
    const [data, setData] = useState<any>();
    const [startDate, setStartDate] = useState<string | null>(null);
    const [balances, setBalances] = useState([{number: '', balance: ''}]);
    const [balancesOpen, setBalancesOpen] = useState(false);
    const [kind, setKind] = useState<any[]>([]);

    const columns = [
        {
            key: 'name',
            dataIndex: 'create_time',
            title: 'Account Name',
            sorter: (a:any, b:any) => Number(a.mask) - Number(b.mask),
            render: (i: any, d: any) => {
                return (
                    <div>{d.show_name}</div>
                )
            }
        },
        {
            key: 'type',
            dataIndex: 'type',
            title: 'Type',
            width: 150
        },
        {
            key: 'amount_init',
            dataIndex: 'amount_init',
            title: 'amount',
            width: 250,
        },
        {
            key: 'action',
            title: 'Action',
            width: 150,
            render: (i: any, d: any) => {
                return (
                    <div className='flex items-center'>
                        <div onClick={() => {
                            setOpen(true);
                            setData(d);
                            setAccountBalance(d.amount_init);
                        }} className='text-primary cursor-pointer'>Edit
                        </div>
                        <Popconfirm
                            title="Delete Account Balance"
                            description="Are you sure to delete?"
                            onConfirm={()=>{
                                delAccount.mutate({id: d.id});
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button onClick={() => {

                            }} danger type={'link'}>Delete</Button>
                        </Popconfirm>

                    </div>
                )
            }
        }
    ];


    useQuery(['kind-list'], KindApi.getList, {
        enabled: list.length > 0,
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                const _list = result.data.data.map((e: any) => ({value: e.number, label: e.name}));

                formatKind(_list);
            } else {
                messageApi.error(result.data.message);
            }
        }
    })

    const formatKind = (_list: any[], arr?: any[]) => {


        const _data = arr ? [...arr] : [...list];

        const _arr: any[] = [];

        _list.forEach((item) => {
            let status = true;
            _data.forEach((_item) => {
                if (_item.mask === item.value) {
                    status = false;
                }
            })

            if (status) {
                _arr.push(item);
            }
        });
        
        setKind(_arr);
    }

    const changeFormatKind = (value: string) => {
        const _list = [...kind];
        const _balances = [...balances];

        const _arr: any[] = [];
        
        _list.forEach((item) => {
            let status = true;
            _balances.forEach((_item) => {
                if (_item.number === item.value) {
                    status = false;
                }
            })

            if (status && item.value != value) {
                _arr.push(item);
            }
        });

        // const _list = [...kind].find((_value, index, obj)=>_value != value);
        if (_list.length > 0) {
            return _arr;
        } else {
            return [];
        }
    }


    useQuery(['gte_start_date'], PlaidApi.getStartDate, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                setStartDate(result.data.data.val);
            } else {
                messageApi.error(result.data.msg);
            }
        }
    })

    // useQuery(['gte_account_balances'], PlaidApi.getAccountBalances, {
    //     onSuccess: (result) => {
    //         if (result.data.status === 'success') {
    //             const list = result.data.data.map((e:any) => ({number:e.account_id, balance: e.amount_init}));
    //             setBalances(list);
    //         }else{
    //             messageApi.error(result.data.message);
    //         }
    //     }
    // })
    const delAccount = useMutation(PlaidApi.delAccountAmount, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                messageApi.success('success');
                setOpen(false);
                queryClient.refetchQueries(['getAccountAmount'])
            } else {
                messageApi.error(result.data.msg)
            }

        }
    })


    useQuery(['getAccountAmount', {page: page, page_size: pageSize}], PlaidApi.getAccountAmount, {
        onSuccess: (result) => {

            if (result.data.limit > 0) {

                const _data: any[] = [];

                result.data.data.forEach((item: any) => {
                    _data.push(item);
                })


                formatKind(kind, _data);
                setList(_data);
                setTotal(result.data.limit);
            }
        }
    })


    const setStartDateApi = useMutation(PlaidApi.setStartDate, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                messageApi.success('success');
            } else {
                messageApi.error(result.data.message);
            }
        }
    })

    const editApi = useMutation(PlaidApi.editAccountAmount, {
        onSuccess: (result: any) => {
            if (result.data.status === 'success') {
                messageApi.success('success');
                setOpen(false);
                queryClient.refetchQueries(['getAccountAmount'])

            } else {
                messageApi.error(result.data.msg);
            }
            setName('debit');
            setAccountBalance('');
        }
    })

    const saveApi = useMutation(PlaidApi.spendingAccount, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {

                setBalances([{number: '', balance: ''}]);
                queryClient.refetchQueries(['getAccountAmount']);
                messageApi.success('success');
            } else {
                messageApi.error(result.data.msg);
            }
        }
    })

    const changePage = (index: number) => {
        setPage(index);
    }


    const submit = () => {
        editApi.mutate({id: data?.id || '', amount: accountBalance});
    }

    const onChange = (data: any, dataString: string) => {
        setStartDate(dataString);
        setStartDateApi.mutate({
            name: 'date_start',
            val: dataString
        })
    }

    const add = () => {
        setBalances((_value: any[]) => {
            return [..._value, {number: '', balance: ''}];
        })
    }

    const save = () => {
        saveApi.mutate(balances);
    }

    const deleteBalance = (index: number) => {

        const _list = [...balances];
        _list.splice(index, 1);
        setBalances(_list);
    }

    const changeBalance = (value: any, type: 'number' | 'balance', index: number) => {
        const _list = [...balances];
        if (type === 'number') {
            _list[index].number = value;
        }

        if (type === 'balance') {
            _list[index].balance = value;
        }

        setBalances(_list);
    }


    return (
        <div className='w-full p-2'>
            {contextHolder}
            {/*<Button type='primary' className='mb-2' onClick={() => setOpen(true)}>set Balance</Button>*/}

            <div className='flex items-center justify-start items-center mb-2'>
                <DatePicker value={startDate ? dayjs(startDate, ['YYYY-MM-DD']) : undefined} className=''
                            onChange={onChange}/>
                <div>
                    <Button className="ml-2" onClick={() => {
                        setBalancesOpen(true);
                    }}>Add</Button>
                </div>
            </div>


            <div className='w-full h-250'>
                <Table
                    columns={columns}
                    size={'small'}
                    pagination={false}
                    dataSource={list}
                />
            </div>

            <Modal title='Add Balance' width={640} destroyOnClose={true} footer={null} open={balancesOpen}
                   onCancel={() => {
                       setBalancesOpen(false);
                   }}>

                <div className='w-600 pb-3'>
                    <div className='flex items-center'>
                        <div style={{width: 'calc(100% / 1.6)'}}>Name</div>
                        <div style={{width: 'calc(100% / 1.6)'}}>Balance</div>
                        <div className='pl-2' style={{width: 'calc(100% / 5.4)'}}>Action</div>
                    </div>
                    {
                        balances.map((item, index) => {

                            const result = changeFormatKind(item.number);

                            return (
                                <div key={index} className='flex mt-2 items-center w-full'>
                                    <div className=' pr-2' style={{width: 'calc(100% / 1.6)'}}>
                                        <Select value={item.number}
                                                options={result}
                                                className='w-full'
                                                onChange={(e) => {
                                                    changeBalance(e, 'number', index);
                                                }}/>
                                    </div>
                                    <div className=' pr-2' style={{width: 'calc(100% / 1.6)'}}>
                                        <Input value={item.balance} onChange={(e) => {
                                            changeBalance(e.target.value, 'balance', index);
                                        }}/>
                                    </div>
                                    <div style={{width: 'calc(100% / 5.4)'}}>
                                        <Button onClick={() => deleteBalance(index)} danger type='link'>DELETE</Button>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className='flex items-center mt-3 justify-between'>
                        <Button onClick={add}>ADD</Button>
                        <Button className='mr-1' type={'primary'} onClick={save}>Save</Button>
                    </div>
                </div>
            </Modal>

            <Modal title='Edit Balance' destroyOnClose={true} footer={null} open={open} onCancel={() => {
                setOpen(false);
            }}>
                <div className='font-bold text-l mt-2 mb-1'>balance</div>
                <Input value={accountBalance} onChange={(e) => {
                    setAccountBalance(e.target.value);
                }} placeholder={'Please enter the balance'}/>
                <div className='mt-3 flex items-center justify-end'>
                    <Button type={'primary'} onClick={submit}>set</Button>
                </div>
            </Modal>


        </div>
    )
}

export default SetBalance;
