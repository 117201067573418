import React, {useEffect, useState} from "react";
import { Button, Form, Input, message, Modal, Pagination, Popconfirm, Select, Table } from 'antd';
import {useNavigate} from "react-router-dom";
import {AppRoutePath} from "../../routers";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import KindApi, {IGroup} from "../../common/api/kind";
import {getUserInfo} from "../../common/utils";

const Kind = () => {

    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [open, setOpen] = useState<boolean>(false);
    const [list, setList] = useState([]);
    const [messageApi, contextHolder] = message.useMessage();
    const [editData, setEditData] = useState(null);
    const [userInfo, setUserInfo] = useState({rule: 0, id: null});
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [total, setTotal] = useState(0);
    const [nameBy, setNameBy] = useState<'descend' | 'ascend' | null>(null);
    const [selectType,setSelectType] = useState<number>(1);
    
    const [primeOptions,setPrimeOptions] = useState<{value:number,label:string}[]>([]);
    
    useEffect(() => {
        const jsonUser: any = localStorage.getItem('userInfo');


        if (jsonUser) {
            const userData = JSON.parse(jsonUser);

            setUserInfo({rule: userData.role, id: userData.id});
        }
    }, [])
    
    useQuery(['getKindList', {page: pageIndex, page_size: pageSize,nameby:nameBy}], KindApi.getPageList, {
        onSuccess: (result) => {
            if (result.data.status === "success") {
                setList(result.data.data)
                setTotal(result.data.limit);
            }
        }
    })

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            width: 500,
            key: 'name',
            sorter: true,
            sortOrder: nameBy
        },
        {
            title: 'Group',
            dataIndex: 'group_info',
            key: 'group',
        },
        {
            title: 'Prime/Sub',
            dataIndex: 'belong_to_name',
            key: 'belong_to_name',
        },
        {
            title: 'Action',
            width: 300,
            render: (item: any) => {
                return (
                    <div>
                        {
                                <Button onClick={() => edit(item)} type={"link"}>Edit</Button>
                        }
                        
                        
                        {
                            userInfo.id === item.user_id && (
                                <Popconfirm
                                    title="Delete Confirm"
                                    description="Are you sure to delete this data?"
                                    onConfirm={() => remove(item)}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button danger type={"link"}>Delete</Button>
                                </Popconfirm>
                            )
                        }


                    </div>
                )
            }
        },
    ];


    const removeKind = useMutation(KindApi.remove, {
        onSuccess: (result) => {
            console.log(result);
            if (result.data.status === 'success') {
                // setOpen(false);
                queryClient.resetQueries(['getKindList', {page: 1}]);
            } else {
                messageApi.error(result.data.msg)
            }
        }
    });
    
    const getSubKindList = useMutation(KindApi.getKindList, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                setPrimeOptions(result.data.data)
            } else {
                messageApi.error(result.data.msg)
            }
        }
    });

    const createKind = useMutation(KindApi.create, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                setOpen(false);
                setEditData(null);
                queryClient.resetQueries(['getKindList', {page: 1}]);
            } else {
                messageApi.error(result.data.msg)
            }
        }
    });


    const edit = (item: any) => {
        setOpen(true);
        setSelectType(parseInt(item.number[0]));

        setEditData({...item,number:item.number.substring(1,item.number.length), name: item.name.replaceAll(`${item.number} `, '')});
        
        getSubKindList.mutate(item.number[0]);
    }

    const remove = (item: any) => {
        removeKind.mutate(item.id);
    }

    const submit = (data: any) => {


        console.log('data',data);

        // @ts-ignore
        if (!!editData?.id) {
            // @ts-ignore
            createKind.mutate({...data,number:`${selectType}${data.number}`, id: editData?.id});
        } else {
            createKind.mutate({...data,number:`${selectType}${data.number}`});
        }


    }

    const expandedRowRender = (_data: any) => {

        const {record, index, indent, expanded} = _data;

        console.log('------1');
        console.log(_data);
        console.log('------1');

        const columns: any = [
            {title: 'Date', dataIndex: 'date', key: 'date'},
            {title: 'Name', dataIndex: 'name', key: 'name'},
            {
                title: 'Status',
                key: 'state',
            },
            {title: 'Upgrade Status', dataIndex: 'upgradeNum', key: 'upgradeNum'},
            {
                title: 'Action',
                dataIndex: 'operation',
                key: 'operation',
            },
        ];

        const data = [];
        for (let i = 0; i < 3; ++i) {
            data.push({
                key: i.toString(),
                date: '2014-12-24 23:12:00',
                name: 'This is production name',
                upgradeNum: 'Upgraded: 56',
            });
        }
        return <Table columns={columns} dataSource={data} pagination={false}/>;
    };

    return (
        <div className='w-full h-full'>
            {contextHolder}
            <div>
                <div className='w-full pl-2 pr-2 pt-2 flex items-center justify-between'>
                    <Button onClick={() =>{
                        setSelectType(1);
                        setOpen(true)
                    } }>Add Chart of Account</Button>
                </div>
                <div className='pl-2 mt-2 pr-2'>
                    <Table size={'small'} onChange={(pagination, filters, sorter: any)=>{
                        console.log(sorter);
                        if (sorter.field === "name") {
                            if (sorter.order === "ascend") {
                                setNameBy('ascend');
                            } else if (sorter.order === "descend") {
                                setNameBy('descend');
                            }else{
                                setNameBy(null);
                            }
                        }
                    }} dataSource={list} pagination={false} columns={columns}/>
                    <div className='flex items-center justify-end' style={{height:'70px'}}>
                        <Pagination current={pageIndex}
                                    total={total}
                                    pageSize={pageSize}
                                    pageSizeOptions={['10', '20', '30', total.toString()]}
                                    size={'small'}
                                    showLessItems={true}
                                    onShowSizeChange={(current, size) => {
                                        setPageIndex(current)
                                        setPageSize(size)
                                    }}
                                    onChange={(current,size)=>{
                                        setPageIndex(current)
                                        setPageSize(size)
                                    }}/>
                    </div>
                </div>
            </div>

            <Modal
                title={editData ? 'Edit Chart of Account' : 'Add Chart of Account'}
                open={open}
                width={400}
                destroyOnClose={true}
                footer={null}
                onCancel={() => {
                    setOpen(false);
                    setEditData(null);
                }}
            >
                <Form initialValues={editData || {group:1}} className='mt-3' onFinish={submit}>
                    <Form.Item labelCol={{span: 6}} label='Number' name={'number'}>
                        <Input placeholder={'0000'} prefix={<div>{selectType}</div>}/>
                    </Form.Item>
                    <Form.Item labelCol={{span: 6}} label='Name' name={'name'}>
                        <Input/>
                    </Form.Item>
                    {/*<Form.Item labelCol={{span: 6}} label='sort' name={'sort'}>*/}
                    {/*  <Input/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item labelCol={{span: 6}} label='Group' name={'group'}>
                        <Select
                            options={[
                            {value: 1, label: IGroup[1]},
                            {value: 2, label: IGroup[2]},
                            {value: 3, label: IGroup[3]},
                            {value: 4, label: IGroup[4]},
                            {value: 5, label: IGroup[5]},
                            {value: 6, label: IGroup[6]},
                            {value: 7, label: IGroup[7]},
                            {value: 8, label: IGroup[8]},
                            {value: 9, label: IGroup[9]},
                        ]}
                            onChange={(value)=>{
                                setSelectType(value);
                            }}
                        />
                    </Form.Item>
                    <Form.Item labelCol={{span: 6}} label='Prime/Sub' name={'belong_to'}>
                        <Select
                            options={primeOptions}
                            fieldNames={{label: 'name', value: 'id'}}
                        />
                    </Form.Item>
                    <Form.Item>
                        <div className='w-full flex items-center justify-end'>
                            <Button onClick={() => {
                                setOpen(false)
                                setEditData(null);
                            }}>取消</Button>
                            <Button htmlType={'submit'} type={'primary'} className='ml-2'>添加</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}

export default Kind;
