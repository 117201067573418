import React, {useState} from 'react';
import {Pagination, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {useQuery} from "@tanstack/react-query";
import BankTradeApi from "../../common/api/bankTrade";


const BankStatement = () => {


    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(20);

    const tables: ColumnsType = [
        {title: 'Date', dataIndex: 'day', key: 'date', width: 120},
        {
            title: 'Description',
            dataIndex: 'desc',
            key: 'description',
        },

        {
            title: 'amount before',
            dataIndex: 'amount_b',
            key: 'amount_b',
            width: 200
        },
        {
            title: 'amount',
            dataIndex: 'amount', key: 'amount', width: 200,
        },

        {
            title: 'amount now',
            dataIndex: 'amount_n',
            key: 'amount_n',
            width: 200,
        }
    ];

    useQuery(['bankTradeApi_getList', {page: page, page_size: pageSize}], BankTradeApi.getList, {
        onSuccess: (result) => {
            setList(result.data.data);
        }
    })

    const changePage = (index: number) => {
        setPage(index);
    }

    return (
        <div className='p-2 w-full h-full flex flex-col'>
            <div className='text-22 mb-2'>Bank Statement</div>
            <div className='flex flex-1 flex-col'>
                <div className='flex-1 rounded-md border border-solid border-gray-200'>
                    <Table
                        columns={tables as any}
                        pagination={false}
                        scroll={{y: '70vh'}}
                        dataSource={list}/>
                    <div className='flex items-center pt-2 justify-end'>
                    <Pagination current={page}
                                total={total}
                                pageSize={20}
                                pageSizeOptions={['10', '20', '50', total.toString()]}
                                size={'small'}
                                showLessItems={true}
                                onShowSizeChange={(current, size) => {
                                    setPage(current);
                                    setPageSize(isNaN(size) ? total : size);
                                }}
                                onChange={changePage}/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default BankStatement;