/* eslint-disable */

import React from 'react';
import { getIconColor } from './helper';

const DEFAULT_STYLE = {
  display: 'block',
};

const IconTianjia = ({ size, color, style: _style, ...rest }) => {
  const style = _style ? { ...DEFAULT_STYLE, ..._style } : DEFAULT_STYLE;

  return (
    <svg viewBox="0 0 1024 1024" width={size + 'px'} height={size + 'px'} style={style} {...rest}>
      <path
        d="M788.376122 191.775263c23.472493 0 42.65002 19.077643 42.650019 42.65002v554.050722c0 23.472493-19.077643 42.65002-42.650019 42.650019H234.425283c-23.472493 0-42.65002-19.077643-42.65002-42.650019V234.425283c0-23.472493 19.077643-42.65002 42.65002-42.65002h553.950839m0-42.650019H234.425283c-47.044869 0-85.200156 38.155287-85.200156 85.200156v554.050722c0 47.044869 38.155287 85.200156 85.200156 85.200156h554.050722c47.044869 0 85.200156-38.155287 85.200156-85.200156V234.425283c0-47.144752-38.155287-85.300039-85.300039-85.300039z"
        fill={getIconColor(color, 0, '#333333')}
      />
      <path
        d="M514.297308 298.350371h-5.693328c-10.188061 0-18.478346 8.290285-18.478346 18.478345v152.02185H338.103785c-10.188061 0-18.478346 8.290285-18.478346 18.478345v5.693329c0 10.188061 8.290285 18.478346 18.478346 18.478345h152.021849V663.422552c0 10.188061 8.290285 18.478346 18.478346 18.478345h5.693328c10.188061 0 18.478346-8.290285 18.478346-18.478345V511.400702h152.021849c10.188061 0 18.478346-8.290285 18.478346-18.478345v-5.693329c0-10.188061-8.290285-18.478346-18.478346-18.478345H532.675771V316.728833c0-10.188061-8.190402-18.378463-18.378463-18.378462z"
        fill={getIconColor(color, 1, '#333333')}
      />
    </svg>
  );
};

IconTianjia.defaultProps = {
  size: 18,
};

export default IconTianjia;
