import React, {useEffect, useRef, useState} from "react";
import {Select, Table, Tooltip} from "antd";
import {formatMoney} from "../../common/utils";
import {useQuery} from "@tanstack/react-query";


const PlaidClassList = (props:{data:any})=>{

    const {data} = props;

    const dom:any = useRef();

    const [height,setHeight] = useState(200);


    const columns = [
        {title: 'Date', dataIndex: 'day', key: 'date', width: 120},
        {
            title: 'Description',
            dataIndex: 'desc',
            key: 'description',
            width:300,
        },
        {
            key: 'trade',
            dataIndex: 'trade_type_info',
            title: 'Trade Type',
            width: 150,
            render: (r:any,data:any) => {
                return (
                    <div>{data.plaid.amount < 0 ? 'receiving' : 'spending'}</div>
                )
            }
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            width: 150,
        }
    ];

    useEffect(()=>{

        if (dom.current.offsetHeight) {

            console.log(dom.current.offsetHeight);

            setHeight(dom.current.offsetHeight)
        }

    },[dom])

    // useQuery({
    //     queryFn:
    // })


    return (
        <div ref={dom} className='flex w-full h-full flex-col' style={{flex: 1}}>
            {
                // @ts-ignore
                <Table columns={columns} className='w-full h-full' virtual scroll={{x:600,y:height - 50}} pagination={false} dataSource={data}/>
            }

        </div>
    )
}

export default PlaidClassList;
