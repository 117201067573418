import axios, { AxiosRequestConfig } from 'axios';
import history from '../routers/history';

const Instance = axios.create({
    // baseURL:'http://101.34.182.188:10086/'
    // baseURL:'https://books.technologycredit.io/'
});

interface IRequest<T> extends AxiosRequestConfig {
    authToken?: boolean;
}

let _token = '';

export const initRequestToken = (value: string) => {
    localStorage.setItem('token', value);
    _token = value;
}

// 添加请求拦截器
Instance.interceptors.request.use(
    function (config: any) {
        if (config?.authToken) {
            
            if (_token) {
                config.headers = { Authorization: `Bearer ${ _token }` };
            } else {
                // @ts-ignore
                _token = localStorage.getItem('token');
                config.headers = { Authorization: `Bearer ${ _token }` };
            }
            
            
        }
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    }
);

// 添加响应拦截器
Instance.interceptors.response.use(
    function (response) {
        
        console.log(response.data.code);
        
        if (response.data.code === '403') {
            window.location.replace('/login');
        }
        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        return response;
    },
    function (error) {
        console.log(error);
        if (error.response.status === 401) {
            window.location.href = '/';
        }
        //window.location.href=`https://openapi.wps.cn/oauthapi/v2/authorize?response_type=code&appid=AK20220704KRSZUN&autologin=false&redirect_uri=${redirect_uri}&scope=user_info,vas&state=ftswps`;
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么
        
    }
);

const request = <T>(config: IRequest<T>) => {
    
    console.log(config);
    
    return Instance.request(config);
};

export const GET = <T>(url: string, params?: T, authToken?: boolean) => {
    const result = request({ method: 'GET', params, url, authToken });
    return result;
};

export const POST = <T>(url: string, data?: T, authToken?: boolean) => {
    const params: any = { method: 'POST', data, url, authToken }
    // @ts-ignore
    if (data?.export) {
        params.responseType = 'blob';
    }
    
    return request(params);
};

export const Download = <T>(url: string, data?: T, authToken?: boolean) => {
    const result = axios.post(url, data, {
        headers: {},
        responseType: 'blob'
    });
    // {method: "POST", params:data, url,responseType: 'blob', authToken}
    return result;
};

export const down2 = (url: string, data: any, callback: any) => {
    const token = '';
    return axios
        .post(url, data, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${ token }`
            }
        })
        .then((res: any) => res)
        .then((response) => callback(response))
        .catch((error) => {
            if (error.response) {
                callback(error.response);
            }
            if (error.name === 'AbortError') {
                throw new Error('无法连接到网络');
            }
            throw new Error(error.message);
        });
};

export const DELETE = <T>(url: string, data?: T, authToken?: boolean) => {
    return request<T>({ method: 'DELETE', data, url, authToken });
};
