import React, {useEffect, useRef, useState} from "react";
import {ContainerTwoTone, CreditCardTwoTone} from '@ant-design/icons';
import type {UploadProps} from 'antd';
import {Button, DatePicker, message, Modal, notification, Radio, Select, Spin, Upload} from 'antd';
import {LoadingOutlined} from '@ant-design/icons';
import {useNavigate} from "react-router-dom";
// @ts-ignore
import {Scrollbars} from 'react-custom-scrollbars';
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import TradeApi from "../../common/api/trade";
import {AxiosResponse} from "axios";
import {AppRoutePath} from "../../routers";
import Plaid from "../../common/Plaid";
import {formatMoney} from "../../common/utils";
import dayjs from "dayjs";
import PlaidList from "../../components/PlaidList";
import PlaidApi from "../../common/api/PlaidApi";

const {RangePicker} = DatePicker;

const antIcon = <LoadingOutlined className='mr-1' color={'#FFFFFF'} style={{fontSize: 14}}/>;
const {Dragger} = Upload;

const UploadCSV = () => {

    const queryClient = useQueryClient();

    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [keyNames, seteKeyNames] = useState<string[]>([]);
    const [category, setCategory] = useState<any>();
    const [selectList, setSelectList] = useState<string[]>([]);
    const [api, contextHolder] = notification.useNotification();
    const [isBank, setIsBank] = useState<boolean>(false);
    const [isPlaidOpen, setIsPlaidOpen] = useState(false);
    const [plaidListType, setPlaidListType] = useState(2);
    const [account, setAccount] = useState([]);
    const [formatAccount, setFormatAccount] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [selectId, setSelectId] = useState('');

    const [plaidDate, setPlaidDate] = useState<{ startDate: '', endData: '' }>({startDate: '', endData: ''})
    const [plaidDateError, setPlaidDateError] = useState('');
    const [isPlaidResult, setIsPlaidResult] = useState(false);
    const [user, setUser] = useState<any>(null);
    const [uploadLoading, setUploadLoading] = useState<any>(false);


    const options = [
        {
            label: 'category',
            options: [
                {label: 'Date', value: 'date'},
                {label: 'Description', value: 'description'},
                {label: 'Amount', value: 'amount'},
            ],
        },
        {
            label: 'Disabled or not',
            options: [{label: 'disabled', value: 'no'}],
        },
    ];

    const AmountOptions = [
        {
            label: 'spending',
            key: 'spending',
            value: 'trade_type-0'
        },
        {
            label: 'receiving',
            key: 'receiving',
            value: 'trade_type-1'
        },
    ];

    useEffect(() => {

        const userInfo = window.localStorage.getItem('userInfo');

        if (userInfo){
            const _userInfo = JSON.parse(userInfo);

            setUser(_userInfo);
        }

    }, [])

    const {isLoading} = useQuery({
        queryKey: ['setAccount'],
        queryFn: PlaidApi.queryAccountV1,
        enabled: account?.length === 0,
        onSuccess: (result) => {
            if (result.data.error_code !== 'ITEM_LOGIN_REQUIRED') {
                console.log('result.data',result.data)
                setAccount(result.data.data);
            }
        }
    })

    const asyncQueryAccount = useMutation(PlaidApi.queryAccount, {
        onSuccess: (result) => {
            if (result.data.error_code !== 'ITEM_LOGIN_REQUIRED') {
                setAccount(result.data.accounts);
                getPlaidAccount();
            } else {
                message.error(result.data.msg);
            }
        }
    })


    const uploadBankApi = useMutation(TradeApi.upload, {
        onSuccess: (result) => {
            if (result.data.status === 'success') {
                message.success(`file uploaded successfully.`);
                setList(result.data.data.map((item: any[]) => {
                    const value = item.splice(0, 4);
                    console.log(value);
                    return value;
                }));
                setIsPlaidResult(false);
                seteKeyNames(result.data.titles.splice(0, 4))
                setSelectList([...result.data.titles, 'Unnamed', 'Unnamed'].map(() => 'Unnamed'));
            } else {

            }
        }
    })


    const save = useMutation(TradeApi.save, {
        onSuccess: (result: AxiosResponse<any>) => {
            setUploadLoading(false)
            queryClient.refetchQueries(['getNumber']);
            queryClient.prefetchQuery(['getNumberRecord'])

            if (result.data?.err_lines && result.data?.err_lines.length > 0) {
                result.data.err_lines.forEach((item: any) => {
                    message.warning(item[1]);
                })
            }

            if (result.data.status === 'success') {
                navigate(AppRoutePath.transactions);
            } else {
                api.error({
                    message: 'error',
                    description: result.data.msg
                })
            }
        }
    });


    const setSelect = (index: number, value: any) => {
        const _list: any = {};
        const _select: string[] = [...selectList];


        if (value === 'amount') {
            _select.forEach((item, _index) => {
                if (item === "amount") {
                    _select[_index] = 'Unnamed';
                }
            })

        }


        _select[index] = value;


        _select.map((item, index) => {
            if (item !== "Unnamed") {
                _list[item] = index;
            }
            ;
        })


        console.log(_list);

        setSelectList(_select);
        setCategory(_list);
    }

    const foramtData = () => {
        const _list: any[] = [];
        const _category = {...category};


        list.forEach((item) => {
            const _fData: any = {};


            for (const arrKey in _category) {
                if (arrKey !== 'no') {
                    if (arrKey.length > 0) {

                        if (arrKey.indexOf('trade_type') >= 0) {
                            _fData['trade_type'] = item[_category['amount']] >= 0 ? _category['trade_type-0'] === 99 ? 1 : 0 : _category['trade_type-1'] === 99 ? 1 : 0;
                        } else {
                            _fData[arrKey] = item[_category[arrKey]];
                        }

                    }
                }
            }

            _list.push(_fData);
        })


        return _list;


    }

    const agree = () => {

        let status = false;

        if (isNaN(category.date)) {
            status = true;
        }
        if (isNaN(category.amount)) {
            status = true;
        }
        if (isNaN(category.description)) {
            status = true;
        }
        if (isNaN(category['trade_type-0'])) {
            status = true;
        }
        if (isNaN(category['trade_type-1'])) {
            status = true;
        }


        if (status) {
            return api.warning({
                message: 'warning',
                description: 'Please select Type'
            })
        } else {
            setIsSubmit(true);
        }
    }

    const submit = () => {
        const _data = foramtData()
        setUploadLoading(true)
        save.mutate({data: _data || [], is_bank: isBank || null, account_id: selectId});
    }

    const empty = () => {
        setList([]);
    }


    const viewPlaid = (e: any) => {
        if (plaidListType === 1) {

            if (!plaidDate.startDate) {
                return setPlaidDateError('Please select a date')
            }

            console.log(plaidDate);

        } else {
            setPlaidDate({startDate: '', endData: ''})
        }

        setIsPlaidResult(true);
        setIsPlaidOpen(false);
    }


    const uploadBank = () => {
        window.sessionStorage.removeItem('accounts');
        const dom = document.createElement('input');
        dom.type = 'file';
        // dom.accept = "image/png,image/jpeg,image/gif,image/webp";

        dom.click();

        dom.onchange = () => {

            if (dom.files) {
                const files: File = dom.files[0];

                const form = new FormData();

                form.append('file', files);
                setIsBank(true);
                changeAccounts(true);

                uploadBankApi.mutate(form);
            }
        }
    }


    const changeAccounts = (status: boolean) => {

        const _arr = [...(account || [])];
        const list: any = [];
        console.log(_arr,status )
        _arr.forEach((item: any) => {

            if (status) {

                if (item.mask.startsWith('110')) {
                    list.push(item);
                }
            } else {
                if (item.mask.startsWith('210')) {
                    list.push(item);
                }
            }
        })

        console.log('list',list);

        setFormatAccount(list);
    }

    const uploadCreditCard = () => {
        window.sessionStorage.removeItem('accounts');
        const dom = document.createElement('input');
        dom.type = 'file';
        // dom.accept = "image/png,image/jpeg,image/gif,image/webp";

        dom.click();

        dom.onchange = () => {

            if (dom.files) {
                const files: File = dom.files[0];

                const form = new FormData();

                form.append('file', files);
                setIsBank(false);
                changeAccounts(false);

                uploadBankApi.mutate(form);
            }
        }
    }

    const getPlaidAccount = async () => {

        if ((user?.role === 1 || user?.can_switch_out)){
            const result = await PlaidApi.queryAgreeStatus();
            if(result.data.data.exists){
                setIsPlaidOpen(true);
                setIsPlaidResult(false);
            }else{
                message.success('Thank you for connecting your bank accounts. You are all set!');
            }


        }else{
            message.success('Thank you for connecting your bank accounts. You are all set!');
            setIsPlaidOpen(false);
            setIsPlaidResult(false);
        }
    }

    const getAccoutAsync = () => {
        setIsPlaidResult(false);
        asyncQueryAccount.mutate()
    }

    return (
        <div className='w-full h-full flex flex-col bg-white'>
            {contextHolder}

            <div className='w-full flex items-center justify-between pl-2 pt-1 pb-2 bg-white'>
                <div className='text-22'>UploadCSV</div>

                <div className='flex items-center'>
                    <Button type='primary' onClick={uploadBank} className='mr-2'>Upload Bank</Button>
                    <Button type='primary' onClick={uploadCreditCard} className='mr-2'>Upload Credit Card</Button>
                </div>

            </div>


            <div style={{flex: 1, display: 'flex', flexDirection: 'column', padding: '20px 0'}}>
                {
                    isPlaidResult && (<PlaidList tabs={account} date={plaidDate}/>)
                }

                {
                    !isPlaidResult && list.length > 0 && (
                        <div className='flex flex-col flex-1 w-full'>
                            <Scrollbars style={{flex: 1}}>
                                {list.length > 0 && (
                                    <div style={{padding: '0 20px'}}>
                                        {
                                            // @ts-ignore
                                            <table border="1">
                                                <tr style={{
                                                    position: 'sticky',
                                                    top: -1,
                                                    background: '#FFF',
                                                    border: '1px solid #000'
                                                }}>
                                                    {
                                                        keyNames.map((item, index) => {
                                                            return (
                                                                <th key={index} className='min-w-150'>
                                                                    <div className={'h-full w-full'}>
                                                                        <Select
                                                                            className='w-full border-none'
                                                                            defaultValue={'Unnamed'}
                                                                            key={`type-${index}`}
                                                                            value={selectList[index]}
                                                                            onChange={(v) => setSelect(index, v)}
                                                                            options={options}
                                                                        />

                                                                        {category?.amount === index && (
                                                                            <div className='flex'>
                                                                                <Select
                                                                                    defaultValue={'Unnamed'}
                                                                                    className='w-full'
                                                                                    key={`type-1-${index}`}
                                                                                    style={{width: '100%'}}
                                                                                    onChange={(v) => setSelect(100, v)}
                                                                                    options={AmountOptions}
                                                                                />
                                                                                <Select
                                                                                    defaultValue={'Unnamed'}
                                                                                    className='w-full'
                                                                                    key={`type-2-${index}`}
                                                                                    style={{width: '100%'}}
                                                                                    onChange={(v) => setSelect(99, v)}
                                                                                    options={AmountOptions}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </th>
                                                            )
                                                        })
                                                    }
                                                </tr>

                                                {
                                                    list.map((item: any, index) => {


                                                        return (
                                                            <tr key={index}>
                                                                {
                                                                    item.map((_item: any, _index: number) => {
                                                                        if (category?.amount === _index) {
                                                                            return (
                                                                                <div className='w-full flex'
                                                                                     key={_index + index + _item}>
                                                                                    <td style={{width: 'calc(50%)'}}>{_item > 0 && formatMoney(_item)}</td>
                                                                                    <td style={{width: 'calc(50%)'}}>{_item < 0 && formatMoney(_item)}</td>
                                                                                </div>
                                                                            )
                                                                        } else {
                                                                            return (
                                                                                <td key={_index + index + _item}>{_item}</td>
                                                                            )
                                                                        }

                                                                    })
                                                                }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        }
                                    </div>
                                )}
                            </Scrollbars>
                        </div>
                    )
                }
                {
                    !isPlaidResult && list.length > 0 && (
                        <div className='w-full h-40 flex items-center justify-end mt-2 pl-2 pr-2'>
                            <Button className='mr-2' disabled={save.isLoading} onClick={empty} type={'link'}>empty
                                data</Button>
                            <Button disabled={save.isLoading} onClick={agree} type={'primary'}>{save.isLoading &&
                                <Spin indicator={antIcon} size={'small'}/>}agree</Button>
                        </div>
                    )
                }


            </div>


            <Modal open={isPlaidOpen} footer={null} onCancel={() => {
                setIsPlaidOpen(false)
            }} title='View Plaid Data'>
                <div className='w-full'>
                    <div className='flex w-full flex-col'>
                        <Radio checked={plaidListType === 1} onClick={() => setPlaidListType(1)}>
                            <div className='relative w-full'>
                                <div>Date</div>
                                <div className='absolute w-500' style={{bottom: -60, left: 0}}>
                                    <RangePicker
                                        status={plaidDateError ? 'error' : ''}
                                        onChange={(e: any) => {
                                            const _startDate: any = dayjs(e[0]).format("YYYY-MM-DD") || '';
                                            const _endData: any = dayjs(e[1]).format("YYYY-MM-DD") || '';

                                            setPlaidDateError('');
                                            setPlaidDate({
                                                startDate: _startDate,
                                                endData: _endData
                                            });
                                        }}/>
                                    <div className='text-error h-20'>{plaidDateError}</div>
                                </div>
                            </div>
                        </Radio>
                        <div className='mt-6'>
                            <Radio checked={plaidListType === 2} onClick={() => setPlaidListType(2)}>View All</Radio>
                        </div>
                    </div>
                    <div className='flex items-center justify-end'>
                        <Button onClick={() => setIsPlaidOpen(false)}>cancel</Button>
                        <Button type={'primary'} className='ml-2' onClick={viewPlaid}>ok</Button>
                    </div>
                </div>
            </Modal>


            <Modal open={isSubmit} width={450} onCancel={() => setIsSubmit(false)} onOk={submit} title='Select Account' confirmLoading={uploadLoading}>
                <div>
                    <div className='w-400'>
                        {
                            account?.length > 0 && (
                                <Select
                                    className='w-400'
                                    value={selectId}
                                    options={formatAccount.map((item: any) => {

                                        return {
                                            label: `${item.name} **** ${item.mask}`,
                                            value: item.account_id,
                                        }
                                    })}
                                    onChange={(value, option) => {
                                        setSelectId(value);
                                    }}

                                />
                            )
                        }

                    </div>
                </div>
            </Modal>

        </div>
    )
}

export default UploadCSV;
