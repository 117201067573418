import React from "react";
import {Tabs} from "antd";
import type {TabsProps} from 'antd';
import Card from "../user/card";
import Kind from "../user/kind";
import Rule from "../user/rule";
import SetBalance from "../user/balance";

const Settings = () => {
  const items: TabsProps['items'] = [
    // {
    //   key: '1',
    //   label: `Account`,
    //   children: <Card/>
    // },
    {
      key: '2',
      label: `Chart of Account`,
      children: <Kind/>
    },
    {
      key: '3',
      label: `rule`,
      children: <Rule/>
    },
    {
      key: '4',
      label: `Account Balance`,
      children: <SetBalance/>
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
  };

  return (
    <div className='w-full h-full bg-white setting-view'>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange}/>
    </div>
  )
}

export default Settings;
