import React, {useState} from "react";
import {TabsProps, Tabs, Button, Modal, Input, Form, DatePicker, Select, message} from "antd";
import NewEntries from "./entries/newEntries";
import BookedEntries from "./entries/bookedEntries";
import {useQuery} from "@tanstack/react-query";
import TradeApi from "../../common/api/trade";
import FormItem from "antd/es/form/FormItem";
import KindApi from "../../common/api/kind";
import dayjs from "dayjs";
import {EnterData} from "./modal";
import {formatMoney} from "../../common/utils";

const Transactions = () => {

    const [number, setNumber] = useState<number>(0);
    const [showAdd, setShowAdd] = useState<boolean>(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [kinds, setKinds] = useState([]);
    const [selectType, setSelectType] = useState<number[]>([]);
    const [items,setItems] = useState<any>([
        {
            key: '2',
            label: `New Entries`,
            children: <NewEntries/>
        },
        {
            key: '3',
            label: `Booked Entries ${number > 0 ? `(${number})` : ''}`,
            children:<BookedEntries/>
        },
    ])

    const types = [
        {value: 0, label: 'P&L'},
        {value: 1, label: 'Asset'},
        {value: 2, label: 'Liability'},
        {value: 3, label: 'Equity'},
        {value: 4, label: 'Credit Card Payment'},
    ];

    const trade_type = [
        {key: '01', value: 0, label: 'spending'},
        {key: '02', value: 1, label: 'receiving'}
        // {key: '03', value: 2, label: 'repayment'}
    ];


    useQuery(['getKindList', {page: 1}], KindApi.getList, {
        onSuccess: (result) => {
            if (result.data.status === "success") {
                setKinds(result.data.data);
            } else {
                messageApi.error(result.data.msg);
            }
        }
    })



    useQuery(['getNumberRecord'], TradeApi.getNumberRecord, {
        onSuccess: (result) => {
            setNumber(result.data.limit)
        }
    })

    const renderData = (_type: string | number) => {
        let _list: number[] = [];
        const _arr: any = [];


        console.log(_type);


        switch (_type) {

            // P&L
            case 0:
                _list = [3, 4, 5, 6, 7, 8, 9];
                break;
            // Asset
            case 1:
                _list = [1];
                break;
            // Liability
            case 2:
                _list = [2];
                break;
            // Equity
            case 3:
                _list = [3];
                break;
            case 4:
                _list = [2];
                break;
            default:
                break;
        }


        if(_type != 4){
            _list.forEach((item: number) => {
                kinds.forEach((_item: any) => {
                    if (_item.group === item) {
                        _arr.push({value: _item.id.toString(), label: _item.name});
                    }
                })
            })

            return _arr;
        }else{
            _list.forEach((item: number) => {
                kinds.forEach((_item: any) => {
                    if (_item.group === 2) {
                        if (_item.id == '31'){
                            _arr.push({value: _item.id.toString(), label: _item.name});
                        }
                    }
                })
            })

            return _arr;
        }
    }

    const onFinish = (data: { values: any, errorFields: any, outOfDate: any }) => {

        let _list:{user_id:string;trade_type:number;type:string;kind_id:string;desc:string;amount:string | number;day:string}[] = [];


        const jsonUser: any = localStorage.getItem('userInfo');

        for (const dataKey in data) {
            // @ts-ignore
            const _data = data[dataKey];

            if (_data.category){
                _list.push({
                    type: _data.type,
                    trade_type:_data.debit > 0 ? 0 : 1,
                    kind_id:_data.category,
                    desc:_data.description,
                    day:dayjs(_data.date).format('YYYY-MM-DD'),
                    amount:formatMoney( _data.debit ? parseInt(_data.debit) : _data.credit * -1),
                    user_id:jsonUser.user_id
                });
            }
        }



        _list.forEach((item:any,index)=>{
            TradeApi.create(item).then((result:any)=>{
                console.log(result);
                if (result.status === 200){
                    if (result.data.code === '200'){
                        messageApi.success('success');

                        setShowAdd(false);
                    }
                }else{
                    messageApi.error(result.data.msg);
                }
            });
        })

    };


    return (
        <div className='w-full h-full bg-white relative setting-view'>
            {contextHolder}
            <Tabs defaultActiveKey="1" destroyInactiveTabPane={true} items={items}/>


            <Modal footer={null}
                   width={1300}
                   destroyOnClose={true}
                   afterClose={() => {
                       setSelectType([]);
                   }} open={showAdd} title={'add Booked'} onCancel={() => setShowAdd(false)}>
                {/*<EnterData/>*/}
                <Form className='mt-2 w-full' onFinish={onFinish}>
                    <table border={1} className='w-full border-gray-200 border-none'>
                        {
                            [1,2].map((item: any, index) => {
                                return (
                                        <div className={`w-full ${item === 2 ? 'mt-2' : ''}`} key={index}>
                                            <tr className='h-40 bg-gray-200'
                                                style={{lineHeight: '40px', width: '1300px'}}>
                                                <th style={{width: '120px'}}>Type</th>
                                                <th style={{width: '300px'}}>Category {item.type}</th>
                                                <th style={{width: '150px'}}>Debit</th>
                                                <th style={{width: '150px'}}>Credit</th>
                                                <th style={{width: '330px'}}>Description</th>
                                                <th style={{width: '200px'}}>Date</th>
                                            </tr>

                                            <tr className='w-full' style={{width: '1300px'}}>
                                                <th style={{width: '120px'}}>
                                                    <FormItem name={[item, 'type']}
                                                              style={{marginBottom: 0, width: '120px'}}>
                                                        <Select
                                                            bordered={false}
                                                            style={{width: '100%'}}
                                                            placeholder='select type'
                                                            onChange={(value, option) => {
                                                                const _type: any = selectType ? [...selectType] : [];

                                                                _type[item] = value;

                                                                console.log(_type)

                                                                setSelectType(_type);
                                                            }}
                                                            options={types}
                                                        />
                                                    </FormItem>
                                                </th>
                                                <th style={{width: '300px'}}>


                                                    <FormItem name={[item, 'category']} style={{
                                                        marginBottom: 0,
                                                        display: selectType?.length > 0 ? 'block' : 'none'
                                                    }}>

                                                        <Select
                                                            bordered={false}
                                                            options={renderData(selectType[item])}
                                                        />
                                                    </FormItem>
                                                </th>
                                                <th style={{width: '150px'}}>
                                                    <FormItem name={[item, 'debit']} style={{marginBottom: 0}} className=''>
                                                        <Input bordered={false}/>
                                                    </FormItem>
                                                </th>
                                                <th style={{width: '150px'}}>
                                                    <FormItem name={[item, 'credit']} style={{marginBottom: 0}} className=''>
                                                        <Input bordered={false}/>
                                                    </FormItem>
                                                </th>
                                                <th style={{width: '330px'}}>
                                                    <FormItem name={[item, 'description']} style={{marginBottom: 0}}
                                                              className=''>
                                                        <Input bordered={false}/>
                                                    </FormItem>
                                                </th>
                                                <th style={{width: '200px'}}>
                                                    <FormItem name={[item, 'date']} style={{marginBottom: 0}} className=''>
                                                        <DatePicker bordered={false}/>
                                                    </FormItem>
                                                </th>
                                            </tr>
                                        </div>
                                )
                            })
                        }

                    </table>


                    <div className='flex mt-4 items-center justify-end'>
                        <Button className='mr-2' onClick={() => setShowAdd(false)}>cancel</Button>
                        <Button htmlType="submit" type={'primary'}>add</Button>
                    </div>
                </Form>

            </Modal>
        </div>
    )
}

export default Transactions;
